declare global {
  interface Navigator {
    deviceMemory: number;
  }
}

const detectSlowDevice = (): boolean => {
  const windowGlobal = window;
  let result = false;
  if (windowGlobal.navigator.deviceMemory && windowGlobal.navigator.deviceMemory < 2) {
    result = true;
  }
  if (
    windowGlobal.navigator.hardwareConcurrency &&
    windowGlobal.navigator.hardwareConcurrency < 3
  ) {
    result = true;
  }
  return result;
};
export default detectSlowDevice;

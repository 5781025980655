export const getStepsCount = (typeRequestDict: { [key: string]: boolean }) => {
  const typeArray = [];
  const mainSteps = ['identity', 'recognize_pass', 'registration', 'address', 'privacy_policy'];

  // eslint-disable-next-line no-restricted-syntax
  for (const i in typeRequestDict) {
    // eslint-disable-next-line no-prototype-builtins
    if (typeRequestDict.hasOwnProperty(i) && typeRequestDict[i]) {
      typeArray.push(i);
    }
  }

  return typeArray.reduce((acc, item) => {
    if (item === 'recognize_pass') {
      return acc + 2;
    }
    if (item === 'without_passport_page') {
      return acc - 1;
    }
    if (mainSteps.includes(item)) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

import { createModel } from '@rematch/core';

import type { IRootModel } from './index';

const initialState = false;

export const adressFormStatus = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setAdressFormStatusReducer(state: boolean, payload: boolean) {
      return payload;
    }
  },
  effects: {
    setAdressFormStatus(payload) {
      this.setAdressFormStatusReducer(payload);
    }
  }
});

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { camelizePathname } from '../camelizePathname';
import { urls } from '../constants';
import { showStartScreen } from '../ga/events';
import { useAppDispatch, useAppSelector } from './redux';

export const useActiveStep = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { steps, typeRequest } = useAppSelector((state) => state.config);
  const { setActiveStep } = useAppDispatch().stepper;
  const { setEvent } = useAppDispatch().analytics;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (typeRequest.privacyPolicyOnly) {
      navigate(urls.agreement);
    }
  }, [navigate, typeRequest]);

  useEffect(() => {
    const pathnameCamelCase = camelizePathname(location.pathname);
    if (pathnameCamelCase && steps.dict[pathnameCamelCase]) {
      setActiveStep(pathnameCamelCase);
      setEvent(showStartScreen);
    } else {
      setActiveStep('root');
    }
    setLoading(false);
  }, [location, setActiveStep, steps.dict, setEvent]);

  return loading;
};

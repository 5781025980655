import { createModel } from '@rematch/core';

import type { IRootModel } from './index';

const initialState = false;

export const postPhotoStatus = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setPostPhotoStatusReducer(state: boolean, payload: boolean) {
      return payload;
    }
  },
  effects: {
    setPostPhotoStatus(payload) {
      this.setPostPhotoStatusReducer(payload);
    }
  }
});

import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import loadingPlugin, { ExtraModelsFromLoading } from '@rematch/loading';

import models, { IRootModel } from './models';

type FullModel = ExtraModelsFromLoading<IRootModel>;

export const store = init<IRootModel, FullModel>({
  plugins: [loadingPlugin()],
  models,
  redux: {
    rootReducers: {
      resetStore: () => undefined
    }
  }
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<IRootModel>;
export type iRootState = RematchRootState<IRootModel, FullModel>;
export const dispatchResetStore = () => store.dispatch({ type: 'resetStore' });

import { createModel } from '@rematch/core';

import type { IRootModel } from './index';

const initialState = false;

export const passportStatus = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setPassportStatusReducer(state: boolean, payload: boolean) {
      return payload;
    }
  },
  effects: {
    setPassportStatus(payload) {
      this.setPassportStatusReducer(payload);
    }
  }
});

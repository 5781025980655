import { createModel } from '@rematch/core';

import { accessTokenName, refreshTokenName } from '../../utils/constants';
import findGetParameter from '../../utils/findGetParameter';
import * as localStorageService from '../../utils/localStorage';
import type { IRootModel } from './index';

interface IState {
  sessionId: string;
}

const setTokenToLocalStorage = (accessToken: string, refreshToken: string) => {
  if (accessToken && refreshToken) {
    localStorageService.setToken({ accessToken, refreshToken });
  }
  return accessToken;
};

const initialState: IState = {
  sessionId: setTokenToLocalStorage(
    findGetParameter(accessTokenName),
    findGetParameter(refreshTokenName)
  )
};

export const session = createModel<IRootModel>()({
  state: initialState
});

import { createModel } from '@rematch/core';

import type { IRootModel } from './index';

const initialState = 0;

export const videoStartPageStatus = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setVideoStartPageStatusReducer(state: number, payload: number) {
      return payload;
    }
  },
  effects: {
    setVideoStartPageStatus(payload) {
      this.setVideoStartPageStatusReducer(payload);
    }
  }
});

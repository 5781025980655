import { Models } from '@rematch/core';

/* PLOP_INJECT_MODEL_IMPORT */
import { adressData } from './adress';
import { adressFormStatus } from './adressFormStatus';
import { analytics } from './analytics';
import { camerasCount } from './camerasCount';
import { complete } from './complete';
import { config } from './config';
import { dataType } from './dataType';
import { imageData } from './image';
import { modalsStatus } from './modalsStatus';
import { passportData } from './passport';
import { passportStatus } from './passportStatus';
import { postPhotoStatus } from './postPhotoStatus';
import { recognitionAccuracy } from './recognitionAccuracy';
import { registrationData } from './registration';
import { session } from './session';
import { standVersion } from './standVersion';
import { stepper } from './stepper';
import { verificate } from './verificate';
import { verificationStatus } from './verificationStatus';
import { videoStartPageStatus } from './videoStartPageStatus';

// add interface to avoid recursive type checking
export interface IRootModel extends Models<IRootModel> {
  /* PLOP_INJECT_MODEL_INTERFACE */
  imageData: typeof imageData;
  passportData: typeof passportData;
  passportStatus: typeof passportStatus;
  session: typeof session;
  adressData: typeof adressData;
  verificate: typeof verificate;
  camerasCount: typeof camerasCount;
  verificationStatus: typeof verificationStatus;
  modalsStatus: typeof modalsStatus;
  adressFormStatus: typeof adressFormStatus;
  videoStartPageStatus: typeof videoStartPageStatus;
  dataType: typeof dataType;
  postPhotoStatus: typeof postPhotoStatus;
  recognitionAccuracy: typeof recognitionAccuracy;
  registrationData: typeof registrationData;
  standVersion: typeof standVersion;
  stepper: typeof stepper;
  config: typeof config;
  complete: typeof complete;
  analytics: typeof analytics;
}

const rootModel: IRootModel = {
  /* PLOP_INJECT_MODEL_OBJECT */
  imageData,
  passportData,
  session,
  passportStatus,
  adressData,
  postPhotoStatus,
  verificate,
  camerasCount,
  verificationStatus,
  modalsStatus,
  adressFormStatus,
  videoStartPageStatus,
  dataType,
  recognitionAccuracy,
  registrationData,
  analytics,
  standVersion,
  stepper,
  config,
  complete
};

export default rootModel;

import axios from 'axios';

import type { Dispatch } from '../store';

export interface IFeedbackFields {
  image: string | string[] | null;
  dispatch?: Dispatch;
  // eslint-disable-next-line camelcase
  passport_source?: 'from_camera' | 'from_disk';
}

const url = `/recognise`;
const urlSavePhoto = `/storage/image`;
export const cancelSource = axios.CancelToken.source();

const Photo = {
  postPhoto: (body: IFeedbackFields) => {
    if (body.passport_source === 'from_disk') {
      return axios.post(url, body, {
        cancelToken: cancelSource.token,
        timeout: 60000,
        onUploadProgress: (progressEvent) =>
          body.dispatch?.imageData.setProgress({ progress: progressEvent.loaded })
      });
    }
    return axios.post(url, body, {
      cancelToken: cancelSource.token,
      timeout: 60000
    });
  },
  postSavePhoto: (body: IFeedbackFields) =>
    axios.post(urlSavePhoto, body, {
      cancelToken: cancelSource.token
    })
};

export default Photo;

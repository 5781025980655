import { ReactNode, useEffect } from 'react';

import styled from 'styled-components';

import { screen } from '../../theme';
import Button from '../Button';
import Typography from '../Typography';

const Container = styled.div`
  background-color: var(--color-background);
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 2000;

  @media (max-width: ${screen.xs}) {
    top: auto;
    touch-action: none;
    align-items: center;
  }
`;

const DialogContent = styled.div`
  margin: 128px 64px auto;
  max-width: 768px;
  display: flex;
  align-items: flex-start;
  text-align: left;

  @media (max-width: ${screen.xs}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    padding: 24px;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
  width: 74px;

  & svg {
    height: 74px;
    width: 74px;
  }
`;

const Actions = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;

  @media (max-width: ${screen.xs}) {
    flex-direction: column;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screen.xs}) {
    margin-left: 28px;
  }
`;

const Title = styled(Typography)`
  && {
    text-align: start;
    line-height: 28px;
    font-size: 24px;
    font-weight: 500;
    color: var(--color-black);
    font-family: MTSCompact, serif;

    @media (max-width: ${screen.xs}) {
      padding-top: 24px;
      text-align: center;
    }
  }
`;

const SubTitle = styled(Typography)`
  && {
    padding-top: 16px;
    text-align: start;
    line-height: 24px;
    font-size: 17px;
    color: var(--color-black);
    font-family: MTSCompact, serif;

    @media (max-width: ${screen.xs}) {
      text-align: center;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-left: 0;
  width: 174px;

  @media (max-width: ${screen.xs}) {
    margin: 16px 0 0;
    width: initial;
  }
`;

interface IProps {
  open: boolean;
  onOpen?: (...args: any) => void;
  onSubmit?: () => void;
  onSubmitSecondary?: () => void;
  submitButtonText?: string;
  textContent?: string;
  textHeader?: string;
  secondaryButtonText?: string;
  icon?: ReactNode;
  onRealized?: () => void;
}

const ModalDialog = ({
  open,
  onOpen,
  onSubmit,
  onSubmitSecondary,
  onRealized,
  secondaryButtonText = 'Повторить',
  submitButtonText = 'Подтвердить',
  textHeader = 'Заголовок',
  textContent = 'Текст сообщения',
  icon
}: IProps) => {

  useEffect(() => {
    if (onOpen && open) {
      onOpen();
    }
  }, [onOpen, open]);

  if (open && onRealized) {
    onRealized();
  }

  if (!open) {
    return null;
  }

  return (
    <Container>
      <DialogContent>
        <Icon>{icon}</Icon>
        <TextContent>
          <Title variant="h5">{textHeader}</Title>
          <SubTitle variant="subtitle1">{textContent}</SubTitle>
          <Actions>
            {onSubmitSecondary && (
              <StyledButton onClick={onSubmitSecondary} variant="outlined">
                {secondaryButtonText}
              </StyledButton>
            )}
            {onSubmit && (
              <StyledButton onClick={onSubmit} variant="contained">
                {submitButtonText}
              </StyledButton>
            )}
          </Actions>
        </TextContent>
      </DialogContent>
    </Container>
  );
};

export default ModalDialog;

import { ReactComponent as alertWarning } from './alertWarning.svg';
import { ReactComponent as angle } from './angle.svg';
import { ReactComponent as goBack } from './arrow_sdk.svg';
import { ReactComponent as arrowUp } from './arrowUp.svg';
import { ReactComponent as backgroundPassport } from './backgroundPassport.svg';
import { ReactComponent as backgroundPassportMobile } from './backgroundPassportMobile.svg';
import { ReactComponent as backgroundSelfie } from './backgroundSelfie.svg';
import { ReactComponent as backgroundSelfieMobile } from './backgroundSelfieMobile.svg';
import { ReactComponent as brightness } from './brightness.svg';
import { ReactComponent as camera } from './camera.svg';
import { ReactComponent as cameraAccessError } from './cameraAccessError.svg';
import { ReactComponent as changeOrientation } from './changeOrientation.svg';
import { ReactComponent as checkbox } from './checkbox.svg';
import { ReactComponent as checkboxChecked } from './checkboxChecked.svg';
import { ReactComponent as checkIcon } from './checkIcon.svg';
import { ReactComponent as clarity } from './clarity.svg';
import { ReactComponent as closeIcon } from './closeIcon.svg';
import { ReactComponent as eye } from './eye.svg';
import { ReactComponent as failed } from './failed.svg';
import { ReactComponent as flashIcon } from './flashIcon.svg';
import { ReactComponent as info } from './info.svg';
import { ReactComponent as infoGrey } from './infoGrey.svg';
import { ReactComponent as infoPrimary } from './infoPrimary.svg';
import { ReactComponent as infoSecondary } from './infoSecondary.svg';
import { ReactComponent as lock } from './lock.svg';
import { ReactComponent as modalOk } from './modalOk.svg';
import { ReactComponent as MTSEgg } from './MTSEgg.svg';
import MTSEggBorder from './MTSEggBorder';
import { ReactComponent as passportHelper } from './passportHelper.svg';
import { ReactComponent as passportInfo } from './passportInfo.svg';
import { ReactComponent as passportStart } from './passportStart.svg';
import { ReactComponent as photoNotMatchError } from './photoNotMatchError.svg';
import { ReactComponent as recogniseError } from './recogniseError.svg';
import { ReactComponent as RectMaskBorder } from './RectMaskBorder.svg';
import { ReactComponent as redDot } from './redDot.svg';
import selfieAnimation from './selfieAnimation.gif';
import { ReactComponent as selfieError } from './selfieError.svg';
import { ReactComponent as smile } from './smile.svg';
import { ReactComponent as spinner } from './spinner.svg';
import { ReactComponent as start } from './start.svg';
import { ReactComponent as successFaceDetect } from './successFaceDetect.svg';
import { ReactComponent as zone } from './zone.svg';

const icons = {
  MTSEgg,
  MTSEggBorder,
  RectMaskBorder,
  alertWarning,
  angle,
  arrowUp,
  backgroundPassport,
  backgroundPassportMobile,
  backgroundSelfie,
  backgroundSelfieMobile,
  brightness,
  camera,
  cameraAccessError,
  changeOrientation,
  checkbox,
  checkboxChecked,
  checkIcon,
  clarity,
  closeIcon,
  eye,
  failed,
  flashIcon,
  goBack,
  info,
  infoGrey,
  infoSecondary,
  infoPrimary,
  lock,
  modalOk,
  passportHelper,
  passportInfo,
  passportStart,
  photoNotMatchError,
  recogniseError,
  redDot,
  selfieAnimation,
  selfieError,
  smile,
  spinner,
  start,
  successFaceDetect,
  zone
};

export default icons;

import { createModel } from '@rematch/core';

import { AnalyticsState, Event } from '../../utils/ga/events';
import { type IRootModel } from './index';

const initialState: AnalyticsState = {
  userId: null,
  userAuth: null,
  abonent: null,
  numberType: null,
  accountType: null,
  touchPoint: null,
  currentTariff: null,
  buttonLocation: null,
  event: 'mtsBmtr',
  eventCategory: 'biometriya'
};

declare global {
  interface Window {
    dataLayer: string[];
  }
}

export const analytics = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setAnalytics: (state, payload: AnalyticsState) => ({ ...state, ...payload })
  },
  effects: {
    setEvent(cb: (data: AnalyticsState, page: string | null) => Event, rootState) {
      if (window.dataLayer) {
        const data = cb(rootState.analytics, location.pathname);
        window.dataLayer.push(data as any);
      }
    }
  }
});

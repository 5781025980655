import { createModel } from '@rematch/core';

import ConfigService from '../../services/ConfigService';
import { dataType } from '../../utils/constants';
import { getStepsCount } from '../../utils/getStepsCount';
import { getStepsDict } from '../../utils/getStepsDict';
import { getStepsList } from '../../utils/getStepsList';
import type { IRootModel } from './index';
import { ISteps, ITypeRequest } from './types';

export interface IState {
  postMessageCallback: string;
  dataType: string;
  typeRequest: ITypeRequest;
  editedFields: string[];
  notRequiredFields: string[];
  minAge: number;
  steps: ISteps;
  differentAngles: boolean;
  liveness: 1 | 2 | 3;
}

const initialSteps: ISteps = {
  count: 4,
  labels: [],
  url: {},
  dict: {}
};

const initialState: IState = {
  postMessageCallback: '',
  dataType: dataType.video,
  typeRequest: {
    identity: false,
    verifyPass: false,
    recognizePass: false,
    address: false,
    withoutPassportPage: false,
    registration: false,
    privacyPolicy: false,
    privacyPolicyOnly: false
  },
  editedFields: [],
  notRequiredFields: [],
  minAge: 14,
  steps: initialSteps,
  differentAngles: true,
  liveness: 2
};

export const config = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setConfigReducer(state: IState, payload: IState) {
      return { ...state, ...payload };
    }
  },
  effects: (dispatch) => ({
    async getConfig() {
      const newConfig = await ConfigService.getConfig();

      if (newConfig.data.type_request_dict.privacy_policy) {
        this.setPassportData();
      }

      const typeRequestDict = {
        ...newConfig.data.type_request_dict,
        verify_pass:
          newConfig.data.type_request_dict.verify_pass &&
          newConfig.data.type_request_dict.recognize_pass,
        privacy_policy:
          newConfig.data.calling_service_fields.privacy_policy &&
          newConfig.data.type_request_dict.recognize_pass
      };
      const { dict, url } = getStepsDict(typeRequestDict);

      this.setConfigReducer({
        postMessageCallback: newConfig.data.reply_to_Final,
        dataType: newConfig.data.data_type,
        typeRequest: {
          identity: newConfig.data.type_request_dict.identity,
          verifyPass: newConfig.data.type_request_dict.verify_pass,
          recognizePass: newConfig.data.type_request_dict.recognize_pass,
          address: newConfig.data.type_request_dict.address,
          withoutPassportPage: newConfig.data.type_request_dict.without_passport_page,
          registration: newConfig.data.type_request_dict.registration,
          privacyPolicy: newConfig.data.calling_service_fields.privacy_policy,
          privacyPolicyOnly: newConfig.data.type_request_dict.privacy_policy
        },
        steps: {
          count: getStepsCount(typeRequestDict),
          labels: getStepsList(typeRequestDict),
          dict,
          url
        },
        editedFields: newConfig.data.edit_fields,
        notRequiredFields: newConfig.data.notRequiredFIelds,
        minAge: newConfig.data.min_age,
        differentAngles: newConfig.data.calling_service_fields.different_angles,
        liveness: newConfig.data.liveness.type_id
      });
    },
    setPassportData() {
      const token = localStorage.getItem('access_token');
      if (token) {
        const { passport_data: passportData } = JSON.parse(atob(token.split('.')[1]));
        dispatch.passportData.setPassportReducer(passportData);
      }
    }
  })
});

import { createModel } from '@rematch/core';

import type { IRootModel } from './index';

const initialState = false;

export const recognitionAccuracy = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setRecognitionAccuracyReducer(state: boolean, payload: boolean) {
      return payload;
    }
  },
  effects: {
    setRecognitionAccuracy(payload) {
      this.setRecognitionAccuracyReducer(payload);
    }
  }
});

import { memo } from 'react';

import axios from 'axios';

import { APIRequestURLs, modalsNames, postMessages } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/redux';

const NetworkError = () => {
  const { setModalStatus } = useAppDispatch().modalsStatus;
  const { postMessageCallback } = useAppSelector((state) => state.config);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (typeof error.response && error.response && error.response.status) {
        // eslint-disable-next-line default-case
        switch (error.response.status) {
          case 400: {
            if (!Object.values(APIRequestURLs).includes(error.config.url)) {
              setModalStatus([modalsNames.requestFailedError, true]);
            }
            if (error.config.url === APIRequestURLs.refresh) {
              if (postMessageCallback) {
                try {
                  window.parent.postMessage(
                    JSON.parse(postMessages.tokenError),
                    postMessageCallback
                  );
                } catch {
                  //
                }
              }
              setModalStatus([modalsNames.certificateTimeError, true]);
            }
            break;
          }
          case 401: {
            setModalStatus([modalsNames.certificateTypeError, true]);
            if (postMessageCallback) {
              try {
                window.parent.postMessage(JSON.parse(postMessages.tokenError), postMessageCallback);
              } catch {
                //
              }
            }
            break;
          }
          case 500: {
            setModalStatus([modalsNames.requestFailedError, true]);
            if (postMessageCallback) {
              try {
                window.parent.postMessage(
                  JSON.parse(postMessages.networkError),
                  postMessageCallback
                );
              } catch {
                //
              }
            }
            break;
          }
          case 403: {
            if (error.config.url === '/oauth/refresh') {
              setModalStatus([modalsNames.certificateTimeError, true]);
              if (postMessageCallback) {
                try {
                  window.parent.postMessage(
                    JSON.parse(postMessages.tokenError),
                    postMessageCallback
                  );
                } catch {
                  //
                }
              }
            }
            break;
          }
        }
      } else {
        if (error.message.slice(0, error.message.indexOf(' ')) === 'Timeout') {
          setModalStatus([modalsNames.requestTimeoutFailedError, true]);
        } else {
          setModalStatus([modalsNames.requestFailedError, true]);
        }

        if (postMessageCallback) {
          try {
            window.parent.postMessage(JSON.parse(postMessages.networkError), postMessageCallback);
          } catch {
            //
          }
        }
      }
      // return Error object with Promise
      return Promise.reject(error);
    }
  );

  return null;
};

export default memo(NetworkError);

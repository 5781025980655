import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import styled from 'styled-components';

import BackButton from '../../components/BackButton';
import CircularProgress from '../../components/CircularProgress';
import StyledLoaderContainer from '../../components/CircularProgress/Container';
import HorizontalScreen from '../../components/HorizontalScreen';
import ModalDialogs from '../../components/Modals';
import Stepper from '../../components/Stepper';
import { breakpoints, screen } from '../../theme';
import { postMessages } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/redux';
import { useActiveStep } from '../../utils/hooks/useActiveStep';
import { useDeviceOrientation } from '../../utils/hooks/useDeviceOrientation';
import { isPage404 } from '../../utils/isPage404';
import mobileDetect from '../../utils/mobileDetect';

const StyledRoot = styled.div`
  background-color: var(--color-background);
  padding: 0 24px;

  @media (max-width: ${screen.xs}) {
    height: 100%;
    padding: 0;
  }
`;

const StyledContent = styled.div`
  height: 100%;
  background-color: var(--color-background);
  position: relative;
`;

const RootPage = () => {
  const sm = useMediaQuery(breakpoints.down.sm);
  const showStepper = !sm && isPage404();
  const orientation = useDeviceOrientation();
  const loading = useActiveStep();
  const dispatch = useAppDispatch();
  const { postMessageCallback } = useAppSelector((state) => state.config);
  const showHorizontalScreen = orientation === 'landscape' && mobileDetect();

  useEffect(() => {
    if (postMessageCallback) {
      window.parent.postMessage(JSON.parse(postMessages.iframeContentLoaded), postMessageCallback);
    }

    const handleMessage = (ev: MessageEvent<any>) => {
      if (ev.origin === postMessageCallback) {
        dispatch.analytics.setAnalytics(ev.data);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [dispatch, postMessageCallback]);

  if (loading) {
    return (
      <StyledLoaderContainer>
        <CircularProgress />
      </StyledLoaderContainer>
    );
  }

  if (showHorizontalScreen) {
    return <HorizontalScreen />;
  }

  return (
    <StyledRoot>
      {showStepper && <Stepper />}
      <StyledContent>
        <BackButton />
        <ModalDialogs />
        <Suspense fallback="">
          <Outlet />
        </Suspense>
      </StyledContent>
    </StyledRoot>
  );
};

export default RootPage;

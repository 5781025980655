import { createModel } from '@rematch/core';

import { standVersionGetParemetre } from '../../utils/constants';
import findGetParameter from '../../utils/findGetParameter';
import type { IRootModel } from './index';

interface IState {
  standVersion: string;
}
const setstandVersion = () => {
  const dt = findGetParameter(standVersionGetParemetre);
  localStorage.setItem(standVersionGetParemetre, dt);

  return dt;
};
const initialState: IState = {
  standVersion: setstandVersion()
};

export const standVersion = createModel<IRootModel>()({
  state: initialState
});

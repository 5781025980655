import axios from 'axios';

export interface IFeedbackFields {
  name: string;
  surname: string;
  patronymic: string;
  number: string;
  series: string;
  gender: string;
  birthDate: string;
  deliveryDate: string;
  departmentCode: string;
  birthPlace: string;
  deliveryPlace: string;
}

const url = `/passport`;
export const cancelSource = axios.CancelToken.source();

const PassportService = {
  postPassport: (body: IFeedbackFields) =>
    axios.put(url, body, {
      cancelToken: cancelSource.token
    })
};

export default PassportService;

import { createModel } from '@rematch/core';

import PassportService from '../../services/PassportService';
import mobileDetect from '../../utils/mobileDetect';
import type { IRootModel } from './index';

export interface IState {
  surname: string | null;
  name: string | null;
  patronymic: string | null;
  birthDate: null | Date;
  gender: string | null;
  series: string | null;
  number: string | null;
  deliveryDate: null | Date;
  deliveryPlace: string | null;
  birthPlace: string | null;
  departmentCode: string | null;
  status: boolean | null;
}

interface IPrivacyPolicyState {
  msisdn: string | null;
  issueDate: string | null | Date;
  issuer: string | null;
}

export type TPassportData = IState & Partial<IPrivacyPolicyState>;

const initialState: TPassportData = {
  surname: null,
  name: null,
  patronymic: null,
  birthDate: null,
  gender: null,
  series: null,
  number: null,
  deliveryDate: null,
  deliveryPlace: null,
  birthPlace: null,
  departmentCode: null,
  status: false
};

export const passportData = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setPassportReducer(state: TPassportData, payload: TPassportData) {
      return { ...state, ...payload };
    }
  },
  effects: (dispatch) => ({
    setPassport(payload, { config }) {
      this.setPassportReducer(payload);
      if (config.typeRequest.withoutPassportPage) {
        dispatch.complete.postComplete();
      }
      if (mobileDetect()) {
        if (config.typeRequest.registration) {
          dispatch.videoStartPageStatus.setVideoStartPageStatus(0);
        }
      }
    },
    async postPassport(payload, { config, stepper }) {
      const payloadWithCorrectDates = {
        ...payload,
        birthDate: payload.birthDate.toLocaleDateString('ru-RU'),
        deliveryDate: payload.deliveryDate.toLocaleDateString('ru-RU')
      };
      await PassportService.postPassport(payloadWithCorrectDates);
      if (stepper.activeStep === config.steps.count) {
        dispatch.complete.postComplete();
      }
    }
  })
});

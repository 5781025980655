import styled from 'styled-components';

import icons from '../../assets/icons';

const StyledProgress = styled.div`
  display: flex;
  animation: spin 1.4s infinite linear forwards;

  svg {
    rect {
      fill: var(--color-mts-red);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CircularProgress = () => (
  <StyledProgress>
    <icons.spinner />
  </StyledProgress>
);

export default CircularProgress;

import { createModel } from '@rematch/core';

import type { IRootModel } from './index';

interface IState {
  result: boolean;
  status: boolean;
}

const initialState: IState = {
  result: false,
  status: false
};

export const verificate = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setVerificationReducer(state: IState, payload: IState) {
      return { ...state, ...payload };
    }
  },
  effects: () => ({
    setVerification(payload) {
      this.setVerificationReducer(payload);
    }
  })
});

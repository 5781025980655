import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { screen } from '../../theme';
import { urls } from '../../utils/constants';
import TopButton from '../Button';
import NewTypography from '../NewTypography';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;
  justify-content: center;
  height: 100vh;
`;

const StyledTitle = styled(NewTypography)`
  padding-top: 32px;
`;

const StyledText = styled(NewTypography)`
  color: var(--color-grey-raven);
  text-align: center;
  max-width: 630px;
  font-weight: 400;
  font-size: 24px;

  @media (max-width: ${screen.xs}) {
    text-align: justify;
  }
`;

const StyledButton = styled(TopButton)`
  margin-top: 16px;
  width: 250px;

  @media (max-width: ${screen.xs}) {
    width: 100%;
  }
`;

const Page404 = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(urls.root);
  };

  return (
    <StyledContainer>
      <StyledTitle variant="h1">404</StyledTitle>
      <StyledText>
        Страница не найдена. Вы можете сообщить об ошибке в форме обратной связи
      </StyledText>
      <StyledButton onClick={handleClick}>Вернуться в начало</StyledButton>
    </StyledContainer>
  );
};

export default Page404;

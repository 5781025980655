import { useEffect } from 'react';

import { darken, getContrastRatio, getLuminance, lighten } from '@mui/material';

import { ThemeType } from '../../theme/types';
import { LOCALSTORAGE_DESIGN_COLOR_KEY, LOCALSTORAGE_THEME_KEY } from '../const/localStorage';
import findColorParameter from '../findColorParameter';
import findGetParameter from '../findGetParameter';

export const useCustomTheme = () => {
  const defaultColor = '#FF0032';

  const designColor =
    (findColorParameter(LOCALSTORAGE_DESIGN_COLOR_KEY, defaultColor) as string) ||
    (localStorage.getItem(LOCALSTORAGE_DESIGN_COLOR_KEY) as string) ||
    defaultColor;
  localStorage.setItem(LOCALSTORAGE_DESIGN_COLOR_KEY, designColor);

  const theme =
    (findGetParameter(LOCALSTORAGE_THEME_KEY) as ThemeType) ||
    (localStorage.getItem(LOCALSTORAGE_THEME_KEY) as ThemeType) ||
    'light';
  localStorage.setItem(LOCALSTORAGE_THEME_KEY, theme);

  const adaptiveDesignColor =
    (theme === 'light' && getContrastRatio('#FFF', designColor) === 1) ||
    (theme === 'dark' && getContrastRatio('#000', designColor) === 1)
      ? defaultColor
      : designColor;
  const designColorLuminance = getLuminance(adaptiveDesignColor);
  const lowLuminance = designColorLuminance < 0.5;
  const adaptiveFontColor = lowLuminance ? '#FFF' : '#000';
  const contrastRatio = getContrastRatio(adaptiveFontColor, adaptiveDesignColor);
  const adaptiveHoverColor =
    lowLuminance && contrastRatio > 5
      ? lighten(adaptiveDesignColor, 0.1)
      : darken(adaptiveDesignColor, 0.1);

  useEffect(() => {
    if (theme === 'dark') {
      document.body.className = 'dark';
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--color-mts-red', adaptiveDesignColor);
    root.style.setProperty('--color-mts-hover', adaptiveHoverColor);
    root.style.setProperty('--color-mts-text', adaptiveFontColor);
    document.body.style.background = 'var(--color-background)';

    // eslint-disable-next-line
  }, []);
};

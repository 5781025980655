import { createModel } from '@rematch/core';

import CompleteService from '../../services/CompleteService';
import { modalsNames } from '../../utils/constants';
import type { IRootModel } from './index';

export const complete = createModel<IRootModel>()({
  state: null,
  effects: (dispatch) => ({
    async postComplete() {
      await CompleteService.postComplete();
      dispatch.modalsStatus.setModalStatus([modalsNames.emptyLoading, false]);
      dispatch.modalsStatus.setModalStatus([modalsNames.passportFormModalOk, true]);
    },
    async postCompletePrivacyPolicy(payload) {
      await CompleteService.postCompletePrivacyPolicy(payload);
      dispatch.modalsStatus.setModalStatus([modalsNames.emptyLoading, false]);
      dispatch.modalsStatus.setModalStatus([modalsNames.passportFormModalOk, true]);
    }
  })
});

import styled from 'styled-components';

import icons from '../../assets/icons';

const StyledContainer = styled.div`
  display: flex;
  z-index: 999;
  position: fixed;
  inset: 0;
  background-color: var(--color-always-white);
  width: 100%;
  min-width: -webkit-fill-available;
  height: 100vh;
  min-height: -webkit-fill-available;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const StyledHeader = styled.h2`
  margin: 16px 0 0;
  font-size: 24px;
`;

const StyledText = styled.p`
  font-size: 17px;
  width: 380px;
  text-align: center;
  margin-top: 8px;
`;

const HorizontalScreen = () => (
  <StyledContainer>
    <icons.changeOrientation />
    <StyledHeader>Горизонтальное положение не доступно</StyledHeader>
    <StyledText>Переверните телефон в вертикальное положение и продолжите процесс</StyledText>
  </StyledContainer>
);

export default HorizontalScreen;

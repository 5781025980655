import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { wrapCreateBrowserRouter } from "@sentry/react";

import ErrorModal from '../../components/Modals/ErrorModal';
import Page404 from '../../components/Page404';
import { routes } from '../../config/router/routes';
import { camelizePathname } from '../../utils/camelizePathname';
import { urls } from '../../utils/constants';
import { useAppSelector } from '../../utils/hooks/redux';
import RootPage from './index';

const useAppRouter = () => {
  const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
  const { steps } = useAppSelector((state) => state.config);

  const filteredRoutes = routes.filter((route) => {
    if (route.path) {
      const camelCaseRoutePath = camelizePathname(route.path);
      return steps.dict[camelCaseRoutePath];
    }
    return true;
  });

  return sentryCreateBrowserRouter([
    {
      path: urls.root,
      element: <RootPage />,
      children: filteredRoutes,
      errorElement: <ErrorModal />
    },
    {
      path: '*',
      element: <Page404 />
    }
  ]);
};

export const StepsProvider = () => {
  const router = useAppRouter();

  return <RouterProvider router={router} />;
};

import { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import icons from '../../assets/icons';
import { modalsNames, postMessages, urls } from '../../utils/constants';
import detectSlowDevices from '../../utils/detectSlowDevice';
import {
  closeAfterCantRecognizeFace,
  closeAfterCantRecognizePassport,
  closeAfterCompareSelfieAndPassport,
  noAccessCameraAlert,
  noCameraAlert,
  reloadAccessCamera,
  reloadAfterCantRecognizeFace,
  reloadAfterCantRecognizePassport,
  reloadAfterCompareSelfieAndPassport,
  showErrorCantRecognizePassport,
  showErrorCompareSelfieAndPassport,
  showMessageDataAdded
} from '../../utils/ga/events';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/redux';
import mobileDetect from '../../utils/mobileDetect';
import CircularProgress from '../CircularProgress';
import ModalDialog from '../ModalDialog';

const ModalDialogs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const slowDeviceDetected = detectSlowDevices();

  const { setModalStatus } = useAppDispatch().modalsStatus;
  const { setVideoStartPageStatus } = useAppDispatch().videoStartPageStatus;
  const setAddressFormStatus = useAppDispatch().adressFormStatus.setAdressFormStatus;
  const { setEvent } = useAppDispatch().analytics;
  const postSelfieStatus = useAppSelector((state) => state.loading.effects.imageData.postSelfie);
  const typeRequest = useAppSelector((state) => state.config.typeRequest);
  const postPhotoStatus = useAppSelector((state) => state.postPhotoStatus);
  const recogniseError = useAppSelector((state) => state.modalsStatus.recogniseError);
  const passportNoValidError = useAppSelector((state) => state.modalsStatus.passportNoValidError);
  const selfieVideoModalError = useAppSelector((state) => state.modalsStatus.selfieVideoModalError);
  const verificateData = useAppSelector((state) => state.verificate);
  const verificationModalError = useAppSelector((state) => state.modalsStatus.verificationModalError);
  const cameraAccessError = useAppSelector((state) => state.modalsStatus.cameraAccessError);
  const requestFailedError = useAppSelector((state) => state.modalsStatus.requestFailedError);
  const certificateTimeError = useAppSelector((state) => state.modalsStatus.certificateTimeError);
  const certificateTypeError = useAppSelector((state) => state.modalsStatus.certificateTypeError);
  const selfieLivenessError = useAppSelector((state) => state.modalsStatus.selfieLivenessError);
  const selfieFaceDetectionError = useAppSelector(
    (state) => state.modalsStatus.selfieFaceDetectionError
  );
  const passportNoPhotoError = useAppSelector((state) => state.modalsStatus.passportNoPhotoError);
  const passportFormModalOk = useAppSelector((state) => state.modalsStatus.passportFormModalOk);
  const passportFormModalError = useAppSelector(
    (state) => state.modalsStatus.passportFormModalError
  );
  const accountConfig = useAppSelector((state) => state.config.postMessageCallback);
  const modelLoading = useAppSelector((state) => state.modalsStatus.modelLoading);
  const selfieLoading = useAppSelector((state) => state.modalsStatus.selfieLoading);
  const emptyLoading = useAppSelector((state) => state.modalsStatus.emptyLoading);
  const requestTimeoutFailedError = useAppSelector(
    (state) => state.modalsStatus.requestTimeoutFailedError
  );
  const cameraAccess = useAppSelector((state) => state.modalsStatus.cameraAccess);
  const cameraNotSupportedError = useAppSelector(
    (state) => state.modalsStatus.cameraNotSupportedError
  );
  const passportVideoModalError = useAppSelector(
    (state) => state.modalsStatus.passportVideoModalError
  );
  const photoNotMatchError = useAppSelector((state) => state.modalsStatus.photoNotMatchError);
  const photoFormatError = useAppSelector((state) => state.modalsStatus.photoFormatError);
  const platformBeforeUploadError = useAppSelector(
    (state) => state.modalsStatus.platformBeforeUploadError
  );
  const platformConnectionError = useAppSelector(
    (state) => state.modalsStatus.platformConnectionError
  );
  const platformUnexpectedError = useAppSelector(
    (state) => state.modalsStatus.platformUnexpectedError
  );

  const handleClosePassportVideoModalError = () => {
    setEvent(reloadAfterCantRecognizePassport);
    setVideoStartPageStatus(0);
    setModalStatus([modalsNames.passportVideoModalError, false]);
    navigate(urls.passportStart);
  };

  const handleClosePassportNoValidError = () => {
    setEvent(reloadAfterCantRecognizePassport);
    setVideoStartPageStatus(0);
    setModalStatus([modalsNames.passportNoValidError, false]);
    navigate(urls.passportStart);
  };

  const handleClosePassportNoValidErrorClose = () => {
    setEvent(closeAfterCantRecognizePassport);
    setVideoStartPageStatus(0);
    setModalStatus([modalsNames.passportNoValidError, false]);
    navigate(urls.passportStart);
  };

  const handleCloseRecogniseError = () => {
    setEvent(reloadAfterCantRecognizePassport);
    setVideoStartPageStatus(0);
    setModalStatus([modalsNames.recogniseError, false]);
    navigate(urls.passportStart);
  };

  const handleCloseSelfieVideoModalError = () => {
    setEvent(reloadAfterCantRecognizePassport);
    setVideoStartPageStatus(0);
    setModalStatus([modalsNames.selfieVideoModalError, false]);
    navigate(urls.selfieStart);
  };

  const handleCloseVerificationModalError = () => {
    setEvent(reloadAfterCompareSelfieAndPassport);
    setModalStatus([modalsNames.verificationModalError, false]);
    setModalStatus([modalsNames.photoNotMatchError, false]);
    setVideoStartPageStatus(0);
    navigate(urls.selfieStart);
  };

  const handleCloseVerificationModalErrorClose = () => {
    setEvent(closeAfterCompareSelfieAndPassport);
    setModalStatus([modalsNames.verificationModalError, false]);
    setModalStatus([modalsNames.photoNotMatchError, false]);
    setVideoStartPageStatus(0);
    navigate(urls.selfieStart);
  };

  const handleRequestFailedError = () => {
    setModalStatus([modalsNames.requestFailedError, false]);
    navigate(!typeRequest.identity ? urls.passportStart : urls.root);
  };

  const handleRequestTimeoutFailedError = () => {
    setModalStatus([modalsNames.requestTimeoutFailedError, false]);
    navigate(!typeRequest.identity ? urls.passportStart : urls.root);
  };

  const certificateErrorPostMessage = () => {
    try {
      if ((window as any).NativeJavascriptInterface) {
        // Call Android interface
        try {
          (window as any).NativeJavascriptInterface.postMessage(postMessages.tokenError);
          // eslint-disable-next-line no-empty
        } catch {}
      } else if ((window as any).webkit && (window as any).webkit.messageHandlers) {
        // Call iOS interface

        try {
          (window as any).webkit.messageHandlers.NativeJavascriptInterface.postMessage(
            postMessages.tokenError
          );
          // eslint-disable-next-line no-empty
        } catch {}
      }
    } catch {
      //
    }
  };

  const networkErrorPostMessage = () => {
    try {
      if ((window as any).NativeJavascriptInterface) {
        // Call Android interface
        try {
          (window as any).NativeJavascriptInterface.postMessage(postMessages.networkError);
          // eslint-disable-next-line no-empty
        } catch {}
      } else if ((window as any).webkit && (window as any).webkit.messageHandlers) {
        // Call iOS interface

        try {
          (window as any).webkit.messageHandlers.NativeJavascriptInterface.postMessage(
            postMessages.networkError
          );
          // eslint-disable-next-line no-empty
        } catch {}
      }
    } catch {
      //
    }
  };

  const handleSelfieLivenessError = () => {
    setEvent(reloadAfterCantRecognizeFace);
    setVideoStartPageStatus(0);
    setModalStatus([modalsNames.selfieLivenessError, false]);
    navigate(urls.selfieStart);
  };

  const handleFaceDetectionError = () => {
    setEvent(reloadAfterCantRecognizeFace);
    setVideoStartPageStatus(0);
    setModalStatus([modalsNames.selfieFaceDetectionError, false]);
    navigate(urls.selfieStart);
  };

  const handleFaceDetectionErrorClose = () => {
    setEvent(closeAfterCantRecognizeFace);
    setVideoStartPageStatus(0);
    setModalStatus([modalsNames.selfieFaceDetectionError, false]);
    navigate(urls.selfieStart);
  };

  const handlePassportNoPhotoError = () => {
    setEvent(reloadAfterCantRecognizeFace);
    setVideoStartPageStatus(0);
    setModalStatus([modalsNames.selfieLivenessError, false]);
    navigate(!typeRequest.identity ? urls.passportStart : urls.root);
  };

  const handleCameraNotSupportedError = () => {
    setModalStatus([modalsNames.cameraNotSupportedError, false]);
    navigate(!typeRequest.identity ? urls.passportStart : urls.root);
  };

  const handleSubmitPassportFormModalOk = () => {
    setModalStatus([modalsNames.passportFormModalOk, false]);
    setAddressFormStatus(true);
    if (accountConfig) {
      try {
        window.parent.postMessage(JSON.parse(postMessages.success), accountConfig);
      } catch {
        //
      }
    }
    try {
      if ((window as any).NativeJavascriptInterface) {
        // Call Android interface
        try {
          (window as any).NativeJavascriptInterface.postMessage(postMessages.success);
          // eslint-disable-next-line no-empty
        } catch {}
      } else if ((window as any).webkit && (window as any).webkit.messageHandlers) {
        // Call iOS interface

        try {
          (window as any).webkit.messageHandlers.NativeJavascriptInterface.postMessage(
            postMessages.success
          );
          // eslint-disable-next-line no-empty
        } catch {}
      }
    } catch {
      //
    }
  };

  const handleSubmitPassportFormModalError = () => {
    setModalStatus([modalsNames.passportFormModalError, false]);
  };

  const handleRetryButtonPassportFormModalError = () => navigate(urls.passportVideo);

  const handleCameraAccessError = () => {
    setEvent(reloadAccessCamera);
    setModalStatus([modalsNames.cameraAccessError, false]);
    navigate(-1);
  };

  const handlePhotoFormatError = () => {
    setVideoStartPageStatus(0);
    setModalStatus([modalsNames.photoFormatError, false]);
    navigate(!typeRequest.identity ? urls.passportStart : urls.root);
  };

  const handlePlatformBeforeUploadError = () => {
    setVideoStartPageStatus(0);
    setModalStatus([modalsNames.platformBeforeUploadError, false]);
    navigate(!typeRequest.identity ? urls.passportStart : urls.root);
  };

  const handlePlatformConnectionError = () => {
    setVideoStartPageStatus(0);
    setModalStatus([modalsNames.platformConnectionError, false]);
    navigate(!typeRequest.identity ? urls.passportStart : urls.root);
  };

  const handlePlatformUnexpectedError = () => {
    setVideoStartPageStatus(0);
    setModalStatus([modalsNames.platformUnexpectedError, false]);
    navigate(!typeRequest.identity ? urls.passportStart : urls.root);
  };

  return (
    <>
      <ModalDialog
        icon={<icons.recogniseError />}
        textHeader="Ошибка распознавания"
        open={passportVideoModalError}
        textContent="При анализе фотографии документа, нам не удалось распознать данные. Повторите сканирование придерживаясь рекомендаций"
        submitButtonText="Повторить"
        onOpen={() => setEvent(showErrorCantRecognizePassport)}
        onSubmit={handleClosePassportVideoModalError}
      />

      <ModalDialog
        icon={<icons.infoPrimary />}
        textHeader="К сожалению ваше устройство не поддерживается"
        open={slowDeviceDetected}
        textContent="Характеристики вашего устройства не соответствуют минимальным требованиям"
      />

      <ModalDialog
        icon={<icons.recogniseError />}
        textHeader="Ошибка распознавания"
        open={passportNoPhotoError}
        textContent="При анализе фотографии документа, нам не удалось распознать фотографию лица. Убедитесь, что фото отчетливо видно и попробуйте еще раз"
        submitButtonText="Повторить"
        onOpen={() => setEvent(showErrorCantRecognizePassport)}
        onSubmit={handlePassportNoPhotoError}
      />

      <ModalDialog
        icon={<icons.recogniseError />}
        textHeader="Ошибка распознавания"
        open={passportNoValidError}
        textContent="При проверке фотографии документа, нам не удалось распознать данные. Убедитесь, что фото отчетливо видно и повторите сканирование по нашим рекомендациям"
        submitButtonText="Повторить"
        secondaryButtonText="Закрыть"
        onOpen={() => setEvent(showErrorCantRecognizePassport)}
        onSubmit={handleClosePassportNoValidError}
        onSubmitSecondary={handleClosePassportNoValidErrorClose}
      />

      <ModalDialog
        icon={<icons.recogniseError />}
        textHeader="Ошибка распознавания"
        open={recogniseError}
        textContent="Паспорт недействителен (по данным МВД России)"
        submitButtonText="Повторить"
        onOpen={() => setEvent(showErrorCantRecognizePassport)}
        onSubmit={handleCloseRecogniseError}
      />

      <ModalDialog
        icon={<icons.selfieError />}
        textHeader="Ошибка распознавания"
        open={selfieVideoModalError}
        textContent="Мы не смогли распознать лицо. Сделайте фото ещё раз по нашим рекомендациям"
        submitButtonText="Повторить"
        onOpen={() => setEvent(showErrorCantRecognizePassport)}
        onSubmit={handleCloseSelfieVideoModalError}
      />

      <ModalDialog
        icon={<CircularProgress />}
        textHeader="Подождите чуть-чуть"
        open={location.pathname === urls.passportVideo ? modelLoading : false}
        textContent="Учим нейросеть распознавать паспорт"
      />

      <ModalDialog
        icon={<CircularProgress />}
        textHeader="Подождите чуть-чуть"
        open={location.pathname === urls.selfieVideo ? selfieLoading : false}
        textContent="Учим нейросеть распознавать ваше лицо"
      />

      <ModalDialog
        icon={<CircularProgress />}
        textHeader="Анализируем фото"
        open={mobileDetect() || location.pathname === urls.passportVideo ? postPhotoStatus : false}
        textContent={
          !typeRequest.identity
            ? 'Распознаём данные вашего паспорта'
            : 'Распознаём данные и сверяем ваше селфи с фото в паспорте'
        }
      />

      <ModalDialog
        icon={<CircularProgress />}
        textHeader="Анализируем фото"
        open={mobileDetect() || location.pathname === urls.selfieVideo ? postSelfieStatus : false}
        textContent="Улучшаем качество фото и распознаём лицо"
      />

      <ModalDialog
        icon={<CircularProgress />}
        textHeader="Подождите чуть-чуть"
        open={
          location.pathname === urls.registrationVideo || location.pathname === urls.selfieVideo
            ? modelLoading
            : false
        }
        textContent="Пожалуйста подождите..."
      />

      <ModalDialog
        icon={<CircularProgress />}
        textHeader="Подождите чуть-чуть"
        open={emptyLoading}
        textContent="Проверка займет несколько секунд"
      />

      <ModalDialog
        icon={<icons.infoPrimary />}
        textHeader="Ошибка соединения"
        open={!navigator.onLine}
        textContent="Проверьте ваше подключение к интернету и перезагрузите страницу"
        onRealized={networkErrorPostMessage}
      />

      <ModalDialog
        icon={<icons.cameraAccessError />}
        textHeader="Нет доступа к камере"
        open={cameraAccessError}
        submitButtonText="Обновить"
        onOpen={() => setEvent(noAccessCameraAlert)}
        onSubmit={handleCameraAccessError}
        textContent="Предоставьте доступ к камере в настройках браузера и обновите страницу"
      />

      <ModalDialog
        icon={<icons.photoNotMatchError />}
        textHeader="Фото не совпали"
        open={
          (!verificateData.result && verificateData.status && verificationModalError) ||
          photoNotMatchError
        }
        textContent="Ваше селфи и фото в паспорте различаются. Повторите сканирование по нашим рекомендациям"
        submitButtonText="Повторить"
        secondaryButtonText="Закрыть"
        onOpen={() => setEvent(showErrorCompareSelfieAndPassport)}
        onSubmit={handleCloseVerificationModalError}
        onSubmitSecondary={handleCloseVerificationModalErrorClose}
      />

      <ModalDialog
        icon={<icons.selfieError />}
        textHeader="Ошибка при прохождении liveness детекции"
        open={selfieLivenessError}
        textContent="Процесс съемки должен проходить один человек"
        submitButtonText="Повторить"
        onSubmit={handleSelfieLivenessError}
      />

      <ModalDialog
        icon={<icons.selfieError />}
        textHeader="Не удалось распознать лицо"
        open={selfieFaceDetectionError}
        textContent="Если на вас были очки или головной убор, снимите их и сделайте фото ещё раз"
        submitButtonText="Повторить"
        secondaryButtonText="Закрыть"
        onSubmitSecondary={handleFaceDetectionErrorClose}
        onSubmit={handleFaceDetectionError}
      />

      <ModalDialog
        icon={<icons.infoPrimary />}
        textHeader="Ошибка загрузки"
        open={platformBeforeUploadError}
        textContent="Ошибка при обработке фото"
        submitButtonText="Повторить"
        onSubmit={handlePlatformBeforeUploadError}
      />

      <ModalDialog
        icon={<icons.infoPrimary />}
        textHeader="Ошибка загрузки"
        open={platformConnectionError}
        textContent="Тайм-аут, ошибка подключения или обработки ответа"
        submitButtonText="Повторить"
        onSubmit={handlePlatformConnectionError}
      />

      <ModalDialog
        icon={<icons.infoPrimary />}
        textHeader="Ошибка загрузки"
        open={platformUnexpectedError}
        textContent="Непредвиденная ошибка при отправке данных"
        submitButtonText="Повторить"
        onSubmit={handlePlatformUnexpectedError}
      />

      <ModalDialog
        icon={<icons.infoPrimary />}
        textHeader="Ошибка загрузки"
        open={photoFormatError}
        textContent="Некорректный формат фото (допустимые jpeg, jpg, png)"
        submitButtonText="Повторить"
        onSubmit={handlePhotoFormatError}
      />

      <ModalDialog
        icon={<icons.infoPrimary />}
        textHeader="Ошибка загрузки"
        open={requestFailedError}
        textContent="Проверьте интернет-соединение и повторите попытку еще раз"
        submitButtonText="Вернуться"
        onSubmit={handleRequestFailedError}
      />

      <ModalDialog
        icon={<icons.infoPrimary />}
        textHeader="Сетевая ошибка"
        open={requestTimeoutFailedError}
        textContent="Истекло время ожидания. Повторите операцию позже"
        submitButtonText="Вернуться"
        onSubmit={handleRequestTimeoutFailedError}
      />

      <ModalDialog
        icon={<icons.infoPrimary />}
        textHeader="Ошибка авторизации"
        open={certificateTimeError}
        textContent="Время действия вашей ссылки истекло, обратитесь в службу поддержки"
        onRealized={certificateErrorPostMessage}
      />

      <ModalDialog
        icon={<icons.infoPrimary />}
        textHeader="Ошибка авторизации"
        open={certificateTypeError}
        textContent="Невалидный сертификат"
        onRealized={certificateErrorPostMessage}
      />

      <ModalDialog
        icon={<icons.lock />}
        textHeader="Камера не поддерживается браузером"
        open={cameraNotSupportedError}
        onOpen={() => setEvent(noCameraAlert)}
        textContent="Попробуйте открыть в другом браузере"
        submitButtonText="Вернуться"
        onSubmit={handleCameraNotSupportedError}
      />

      <ModalDialog
        icon={<icons.camera />}
        textHeader="Предоставьте доступ к камере"
        open={cameraAccess}
        textContent="Нажмите Разрешить в левой части адресной строки браузера и продолжите сканирование"
      />

      <ModalDialog
        textHeader="Данные отправлены"
        icon={<icons.modalOk />}
        open={passportFormModalOk}
        textContent="Вы успешно подтвердили свои данные"
        secondaryButtonText="Отлично"
        onOpen={() => setEvent(showMessageDataAdded)}
        onSubmitSecondary={handleSubmitPassportFormModalOk}
      />

      <ModalDialog
        icon={<icons.failed />}
        textHeader="Паспорт недействителен"
        open={passportFormModalError}
        textContent="Отсканируйте паспорт ещё раз или предоставьте действующий документ"
        submitButtonText="Повторить"
        secondaryButtonText="Закрыть"
        onOpen={() => setEvent(showErrorCantRecognizePassport)}
        onSubmitSecondary={handleSubmitPassportFormModalError}
        onSubmit={handleRetryButtonPassportFormModalError}
      />
    </>
  );
};

export default memo(ModalDialogs);

import axios from 'axios';

import { objectRemoveEmptyValues } from '../utils/objectRemoveEmptyValues';

export interface IFeedbackFields {
  index: string;
  region: string;
  district: string;
  city: string;
  street: string;
  house: string;
  block: string;
  numberFlat: string;
}

const url = `/address`;
export const cancelSource = axios.CancelToken.source();

const Adress = {
  postAdress: (body: IFeedbackFields) =>
    axios.post(url, objectRemoveEmptyValues(body), {
      cancelToken: cancelSource.token
    })
};

export default Adress;

import { Step, StepIconProps, StepLabel, Stepper } from '@mui/material';
import styled from 'styled-components';

import icons from '../../assets/icons';
import { useAppSelector } from '../../utils/hooks/redux';
import Typography from '../Typography';

const StyledCircle = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-weight: 500;
  position: relative;
  color: var(--color-grey-chateau);
  border: 2px solid var(--color-white-smoke);
  background: var(--color-white);
`;

const StyledContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  background: var(--color-background);
`;

const StyledStepper = styled(Stepper)`
  padding-top: 18px;
  background: var(--color-background);

  & .MuiStepConnector-root {
    top: 16px;
    left: calc(-100% + 52px);
    right: 100%;
  }

  & .MuiStepLabel-root {
    width: fit-content;
    display: flex;
    align-items: flex-start;

    & .MuiTypography-root {
      text-align: start;
      font-family: MTSCompact, serif;
      font-size: 14px;
      hyphens: auto;
    }
  }

  & .MuiCircularProgress-root {
    color: var(--color-mts-red);
  }
`;

const StyledStepLabel = styled(StepLabel)`
  & .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 6px;
  }

  & .MuiTypography-root {
    color: var(--color-black);
  }
`;

const StyledCirclePrev = styled(StyledCircle)`
  position: relative;
  color: var(--color-black);
  border: 2px solid var(--color-white-smoke);
  background: var(--color-white);
`;

const StyledCircleActive = styled(StyledCircle)`
  color: var(--color-mts-text);
  border: 2px solid var(--color-mts-red);
  background: var(--color-mts-red);
`;

const StyledCircleComplete = styled.div`
  position: absolute;
  left: 22px;
  top: 14px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-weight: 550;
  border: 2px solid var(--color-dark-apple);
  background: var(--color-dark-apple);
`;

const StyledStepConnector = styled.div<IStepConnector>`
  position: absolute;
  border-color: var(--color-light-grey);
  border-top-style: solid;
  border-top-width: 1px;
  width: ${({ stepsCount }) => `calc(100% / ${stepsCount} - 52px)`};
  flex: 1 1 auto;
  right: 0;
  top: 34px;
`;

interface IStepConnector {
  stepsCount: number;
}

const Connector = ({ stepsCount }: IStepConnector) => (
  <StyledStepConnector stepsCount={stepsCount} />
);

const QontoStepIcon = (props: StepIconProps) => {
  const { active, completed, icon: iconIndex } = props;

  if (active) {
    return <StyledCircleActive>{iconIndex}</StyledCircleActive>;
  }

  if (completed) {
    return (
      <StyledCirclePrev>
        {iconIndex}
        <StyledCircleComplete>
          <icons.checkIcon />
        </StyledCircleComplete>
      </StyledCirclePrev>
    );
  }

  return <StyledCircle>{iconIndex}</StyledCircle>;
};

const HorizontalLinearStepper = () => {
  const { activeStep } = useAppSelector((state) => state.stepper);
  const { steps } = useAppSelector((state) => state.config);

  return (
    <StyledContainer>
      <StyledStepper activeStep={activeStep - 1} alternativeLabel>
        {steps.labels.map((label: string) => (
          <Step key={label}>
            <StyledStepLabel StepIconComponent={QontoStepIcon}>
              <Typography variant="subtitle1">{label}</Typography>
            </StyledStepLabel>
          </Step>
        ))}
        <Step last component={Connector} stepsCount={steps.labels.length} />
      </StyledStepper>
    </StyledContainer>
  );
};

export default HorizontalLinearStepper;

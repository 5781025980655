import { createModel } from '@rematch/core';
import { captureException } from '@sentry/react';
import { AxiosError } from 'axios';

import VerificationStatusService from '../../services/VerificationStatusService';
import { APIRequestURLs, modalsNames } from '../../utils/constants';
import type { IRootModel } from './index';

const initialState = false;

export const verificationStatus = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setVerificationStatusReducer(state: boolean, payload: boolean) {
      return payload;
    }
  },
  effects: (dispatch) => ({
    async postVerificationStatus(payload, { config }) {
      try {
        dispatch.modalsStatus.setModalStatus([modalsNames.emptyLoading, true]);
        const { data } = await VerificationStatusService.postVerificationStatus(payload);
        dispatch.modalsStatus.setModalStatus([modalsNames.emptyLoading, false]);
        if (data.result) {
          this.setVerificationStatusReducer(data.result);
          if (config.typeRequest.address) {
            dispatch.adressFormStatus.setAdressFormStatus(true);
          }
        } else {
          dispatch.modalsStatus.setModalStatus([modalsNames.recogniseError, true]);
          captureException(`Error: recognise error`);
        }
      } catch (err) {
        const error = err as AxiosError;
        if (
          error.response &&
          error.response.status === 400 &&
          error.config.url !== APIRequestURLs.refresh
        ) {
          dispatch.modalsStatus.setModalStatus([modalsNames.recogniseError, true]);
          captureException(error);
        }
      }
    }
  })
});

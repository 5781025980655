import { ReactNode } from 'react';

import icons from '../assets/icons';

export enum dataType {
  photo = '4CE206673FDDB1CB9A8ED6A1B7ADF184',
  video = 'CAB8B56CBD01B6CC068EF9E1F6374896',
  all = '3D1AE724C6776764D56E4413E6C67FF0'
}

export enum ESuggestTypes {
  region = 'region',
  area = 'area',
  city = 'city',
  street = 'street_with_type',
  house = 'house'
}

export const selfieHints = [
  'Поместите лицо в рамку и посмотрите в камеру',
  'Полностью поместите лицо в рамку и посмотрите в камеру',
  'Идет сканирование лица. Осталось совсем чуть-чуть',
  'Ваше лицо отсканировано'
];

export interface IHint {
  id: number;
  text: string;
  icon: ReactNode;
}

export const selfieDesktopHints: IHint[] = [
  {
    id: 1,
    text: 'Держите лицо в пределах рамки. Смотрите прямо в камеру',
    icon: <icons.smile />
  },
  {
    id: 2,
    text: 'Ваше лицо должно быть спокойным, волосы не должны закрывать лицо',
    icon: <icons.eye />
  },
  {
    id: 3,
    text: 'В помещении должно быть светло',
    icon: <icons.brightness />
  }
];

export const passportDesktopHints: IHint[] = [
  {
    id: 1,
    text: 'Откройте разворот паспорта на 2-3 странице',
    icon: <icons.passportInfo />
  },
  {
    id: 2,
    text: 'Поместите паспорт в рамку. Данные паспорта должны быть отчетливо видны, без бликов',
    icon: <icons.eye />
  },
  {
    id: 3,
    text: 'Прочитайте подсказки на экране, чтобы правильно отсканировать паспорт',
    icon: <icons.infoGrey />
  }
];

export const passportVideoHints: IHint[] = [
  {
    id: 1,
    text: 'Поместите паспорт полностью в зону сканирования',
    icon: <icons.zone />
  },
  {
    id: 2,
    text: 'Соедините углы паспорта с углами скана, чтобы они стали зелёными',
    icon: <icons.angle />
  },
  {
    id: 3,
    text: 'Наклоните паспорт немного вперёд, чтобы уменьшить количество бликов',
    icon: <icons.brightness />
  },
  {
    id: 4,
    text: 'Держите паспорт ровно, чтобы было чёткое изображение',
    icon: <icons.clarity />
  }
];

export const registrationDesktopHints: IHint[] = [
  {
    id: 1,
    text: 'Откройте страницу паспорта с актуальной пропиской',
    icon: <icons.passportInfo />
  },
  {
    id: 2,
    text: 'Позаботьтесь о хорошем освещении',
    icon: <icons.eye />
  }
];

const modalsNames = {
  passportFormModalOk: 'passportFormModalOk',
  passportFormModalError: 'passportFormModalError',
  passportVideoModalError: 'passportVideoModalError',
  selfieVideoModalError: 'selfieVideoModalError',
  verificationModalError: 'verificationModalError',
  certificateTimeError: 'certificateTimeError',
  certificateTypeError: 'certificateTypeError',
  requestFailedError: 'requestFailedError',
  cameraAccess: 'cameraAccess',
  cameraAccessError: 'cameraAccessError',
  cameraNotSupportedError: 'cameraNotSupportedError',
  modelLoading: 'modelLoading',
  passportNoValidError: 'passportNoValidError',
  passportNoPhotoError: 'passportNoPhotoError',
  recogniseError: 'recogniseError',
  selfieLivenessError: 'selfieLivenessError',
  selfieFaceDetectionError: 'selfieFaceDetectionError',
  requestTimeoutFailedError: 'requestTimeoutFailedError',
  emptyLoading: 'emptyLoading',
  selfieLoading: 'selfieLoading',
  photoNotMatchError: 'photoNotMatchError',
  photoFormatError: 'photoFormatError',
  platformBeforeUploadError: 'platformBeforeUploadError',
  platformConnectionError: 'platformConnectionError',
  platformUnexpectedError: 'platformUnexpectedError',
};

const stepsMap = {
  identity: 'Фото лица',
  recognize_pass: 'Фото паспорта',
  verify_pass: 'Паспортные данные',
  registration: 'Адрес прописки',
  address: 'Адрес регистрации',
  privacy_policy: 'Соглашение'
};

const pathName = window.location.pathname.split('/')[1];

const urls = {
  selfieStart: `${pathName === 'uat' ? '/uat/' : '/'}selfie-start`,
  uploadPhoto: `${pathName === 'uat' ? '/uat/' : '/'}upload-photo`,
  passportStart: `${pathName === 'uat' ? '/uat/' : '/'}passport-start`,
  selfieVideo: `${pathName === 'uat' ? '/uat/' : '/'}selfie-video`,
  passportVideo: `${pathName === 'uat' ? '/uat/' : '/'}passport-video`,
  passportForm: `${pathName === 'uat' ? '/uat/' : '/'}passport-form`,
  registrationStart: `${pathName === 'uat' ? '/uat/' : '/'}registration-start`,
  registrationVideo: `${pathName === 'uat' ? '/uat/' : '/'}registration-video`,
  address: `${pathName === 'uat' ? '/uat/' : '/'}address`,
  finish: `${pathName === 'uat' ? '/uat/' : '/'}finish`,
  agreement: `${pathName === 'uat' ? '/uat/' : '/'}agreement`,
  root: `${pathName === 'uat' ? '/uat/' : '/'}`
};

const postMessages = {
  success: '{"eventId":1,"text":"Успешно"}',
  tokenError: '{"eventId":2,"text":"Токен просрочен или невалиден"}',
  height: (height: number | '100vh') => `{"eventId":3,"text":"${height}px"}`,
  networkError: '{"eventId":4,"text":"Сетевая ошибка"}',
  iframeContentLoaded: '{"eventId":5,"text":"iframe загружен"}'
};

const imagesTypes = ['image/jpeg', 'image/jpg', 'image/png'];
const dataTypeGetParemetre = 'data_type';
const standVersionGetParemetre = 'version';
const refreshTokenName = 'refresh_token';
const accessTokenName = 'access_token';

const dadata = {
  URL: 'address/suggest',
  baseURL: 'https://cm-apim.msk.mts.ru:8243',
  token: 'Bearer 7b1a3fb8-44c8-463b-a999-c8f27dd319a4'
};

const uploadImageErrors = {
  typeError: {
    header: 'Неверный формат файла',
    subtitle: 'Изображение должно быть в формате JPEG, JPG или PNG'
  },
  bigSize: {
    header: 'Слишком большой файл',
    subtitle: 'Размер файла должен быть до 16 МБ'
  }
};

const APIRequestURLs = {
  recognise: '/recognise',
  selfie: '/selfie',
  refresh: '/oauth/refresh',
  verification: '/passport/verification'
};

const successFaceUngle = 0.5;
const ContinuousNSkipMax = 2;
const ContinuousPeriodToStart = 2;
const firstSideTimeoutMs = 30000;
const beFastPromptPeriodMs = 3000;
const sideTimeoutMs = 20000;
const beStraightPromptPeriodMs = 5000;

export {
  stepsMap,
  urls,
  modalsNames,
  imagesTypes,
  uploadImageErrors,
  refreshTokenName,
  accessTokenName,
  dataTypeGetParemetre,
  standVersionGetParemetre,
  dadata,
  APIRequestURLs,
  successFaceUngle,
  postMessages,
  ContinuousNSkipMax,
  ContinuousPeriodToStart,
  firstSideTimeoutMs,
  beFastPromptPeriodMs,
  sideTimeoutMs,
  beStraightPromptPeriodMs
};

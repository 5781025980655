import axios from 'axios';

export interface IFeedbackFields {
  series: string | null;
  number: string;
}

const url = `/passport/verification`;
export const cancelSource = axios.CancelToken.source();

const VerificationStatus = {
  postVerificationStatus: (body: IFeedbackFields) => axios.post(url, body)
};

export default VerificationStatus;

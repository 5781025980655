import { createModel } from '@rematch/core';

import type { IRootModel } from './index';

interface IState {
  passportFormModalOk: boolean;
  passportFormModalError: boolean;
  passportVideoModalError: boolean;
  selfieVideoModalError: boolean;
  verificationModalError: boolean;
  certificateTimeError: boolean;
  certificateTypeError: boolean;
  requestFailedError: boolean;
  requestTimeoutFailedError: boolean;
  cameraAccess: boolean;
  cameraAccessError: boolean;
  cameraNotSupportedError: boolean;
  modelLoading: boolean;
  passportNoValidError: boolean;
  passportNoPhotoError: boolean;
  recogniseError: boolean;
  selfieLivenessError: boolean;
  selfieFaceDetectionError: boolean;
  emptyLoading: boolean;
  selfieLoading: boolean;
  photoNotMatchError: boolean;
  photoFormatError: boolean;
  platformBeforeUploadError: boolean;
  platformConnectionError: boolean;
  platformUnexpectedError: boolean;
  postPhotoStatus: boolean;
}

type PayloadType = [string, boolean];

const initialState: IState = {
  passportFormModalOk: false,
  passportFormModalError: false,
  passportVideoModalError: false,
  selfieVideoModalError: false,
  verificationModalError: false,
  certificateTimeError: false,
  certificateTypeError: false,
  requestFailedError: false,
  cameraAccess: false,
  cameraAccessError: false,
  cameraNotSupportedError: false,
  modelLoading: false,
  passportNoValidError: false,
  recogniseError: false,
  selfieLivenessError: false,
  selfieFaceDetectionError: false,
  passportNoPhotoError: false,
  requestTimeoutFailedError: false,
  emptyLoading: false,
  selfieLoading: false,
  photoNotMatchError: false,
  photoFormatError: false,
  platformBeforeUploadError: false,
  platformConnectionError: false,
  platformUnexpectedError: false,
  postPhotoStatus: true
};

export const modalsStatus = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setModalStatusReducer(state: IState, payload: PayloadType) {
      return { ...state, [payload[0]]: payload[1] };
    }
  },
  effects: () => ({
    setModalStatus(payload: PayloadType) {
      this.setModalStatusReducer(payload);
    }
  })
});

import { ReactNode } from 'react';

import { ButtonProps } from '@mui/material/Button';
import styled from 'styled-components';

export type Variant = 'contained' | 'outlined' | 'main' | 'secondary' | 'special';
type TopButtonProps = Omit<ButtonProps, 'variant'>;

interface ITopButtonProps extends TopButtonProps {
  width?: number;
  variant?: Variant;
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  className?: string;
  small?: boolean;
  loading?: boolean;
  AnimationComponent?: ReactNode;
}

const StyledButton = styled.button<ITopButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
  font-size: 17px;
  min-height: 44px;
  max-height: 44px;
  border: none;
  margin: 8px 16px 12px;
  border-radius: 8px;
  font-family: MTSCompact, serif;
  font-weight: 500;
  line-height: 24px;
  transition: background 200ms linear, color 200ms linear, border 200ms linear;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  background-color: ${({ variant }) =>
    variant === 'contained' ? 'var(--color-mts-red)' : 'var(--color-white-smoke)'};
  color: ${({ variant }) =>
    variant === 'contained' ? 'var(--color-mts-text)' : 'var(--color-text-primary)'};

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-mts-hover);
      color: var(--color-mts-text);
    }
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), var(--color-mts-red);
    color: var(--color-mts-text);
  }

  &:disabled {
    cursor: unset;
    background: var(--color-link-water);
    color: var(--color-grey-heather);
  }
`;

const Button = ({ children, className, variant = 'outlined', ...props }: ITopButtonProps) => (
  <StyledButton {...props} className={className} variant={variant}>
    {children}
  </StyledButton>
);

export default Button;

import AddressPage from '../../routes/AddressPage';
import AgreementPage from '../../routes/AgreementPage';
import FinishPage from '../../routes/FinishPage';
import PassportPage from '../../routes/PassportPage';
import PassportVideoPage from '../../routes/PassportVideoPage';
import RegistrationVideo from '../../routes/RegistrationVideoPage';
import SelfieVideoPage from '../../routes/SelfieVideoPage';
import StartPage from '../../routes/StartPage';
import UploadPhotoPage from '../../routes/UploadPhotoPage';
import VideoStartPage from '../../routes/VideoStartPage';
import { urls } from '../../utils/constants';

export const routes = [
  {
    index: true,
    element: <StartPage />
  },
  {
    path: urls.uploadPhoto,
    element: <UploadPhotoPage />
  },
  {
    path: urls.selfieStart,
    element: <VideoStartPage />
  },
  {
    path: urls.selfieVideo,
    element: <SelfieVideoPage />
  },
  {
    path: urls.passportStart,
    element: <VideoStartPage />
  },
  {
    path: urls.passportVideo,
    element: <PassportVideoPage />
  },
  {
    path: urls.registrationStart,
    element: <VideoStartPage />
  },
  {
    path: urls.registrationVideo,
    element: <RegistrationVideo />
  },
  {
    path: urls.passportForm,
    element: <PassportPage />
  },
  {
    path: urls.address,
    element: <AddressPage />
  },
  {
    path: urls.agreement,
    element: <AgreementPage />
  },
  {
    path: urls.finish,
    element: <FinishPage />
  }
];

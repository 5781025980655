import { stepsMap } from './constants';

export const getStepsList = (dict: { [key: string]: boolean }): string[] => {
  const stepsList: string[] = [];
  Object.entries(stepsMap).forEach(([key, value]) => {
    if (dict[key]) {
      if (key === 'recognize_pass' && !dict.without_passport_page && !dict.verify_pass) {
        stepsList.push(value);
        stepsList.push(stepsMap.verify_pass);
      } else {
        stepsList.push(value);
      }
    }
  });

  return stepsList;
};

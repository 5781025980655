import { createModel } from '@rematch/core';

import { urls } from '../../utils/constants';
import type { IRootModel } from './index';
import { IStepsDict } from './types';

interface IState {
  activeStep: number;
  activeLabel: string;
  nextPageUrl: string;
  prevPageUrl: string;
  videoNextPageUrl: string;
}

const initialState: IState = {
  activeStep: 0,
  activeLabel: '',
  nextPageUrl: '',
  prevPageUrl: '',
  videoNextPageUrl: ''
};

export const stepper = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setActiveStepReducer(_state: IState, payload: IState) {
      return payload;
    }
  },
  effects: () => ({
    setActiveStep(payload: keyof IStepsDict, state) {
      const {
        config: { steps }
      } = state;
      const activeStepUrl = payload;
      const activeStep = steps.dict[payload] as number;
      const activeLabel = steps.labels[activeStep - 1];

      const nextStepUrl = activeStepUrl.includes('Start')
        ? (activeStepUrl.replace('Start', 'Video') as keyof IStepsDict)
        : steps.url[activeStep + 1];

      let prevStepUrl = 'root' as keyof IStepsDict;
      if (activeStep > 0) {
        prevStepUrl = activeStepUrl.includes('Video')
          ? (activeStepUrl.replace('Video', 'Start') as keyof IStepsDict)
          : steps.url[activeStep - 1];
      }

      const videoNextStepUrl = steps.url[activeStep + 1];

      this.setActiveStepReducer({
        activeStep,
        activeLabel,
        nextPageUrl: urls[nextStepUrl],
        prevPageUrl: urls[prevStepUrl],
        videoNextPageUrl: urls[videoNextStepUrl]
      });
    }
  })
});

import { AxiosError } from 'axios';

import { APIRequestURLs, modalsNames } from './constants';

const errors: Record<string, string> = {
  'BioPlatform error': modalsNames.platformConnectionError,
  'Face not recognized': modalsNames.selfieVideoModalError,
  'Incorrect selfie format': modalsNames.photoFormatError,
  'Invalid format image:': modalsNames.platformBeforeUploadError,
  'Liveness check error': modalsNames.selfieFaceDetectionError,
  'Selfie error': modalsNames.selfieFaceDetectionError,
  "The faces didn't match": modalsNames.selfieLivenessError,
  'Unexpected error': modalsNames.platformUnexpectedError,
  default: modalsNames.selfieFaceDetectionError
};

export const postSelfieErrorHandler = (error: AxiosError, dispatch: any) => {
  if (
    error.response &&
    error.response.status === 400 &&
    error.config.url !== APIRequestURLs.refresh
  ) {
    const errorMessage = error.response.data.error;
    let modalName = errors.default;
    Object.entries(errors).forEach(([key, value]) => {
      if (errorMessage.includes(key)) {
        modalName = value;
      }
    });
    dispatch.modalsStatus.setModalStatus([modalName, true]);
  } else {
    dispatch.modalsStatus.setModalStatus([modalsNames.requestTimeoutFailedError, true]);
  }
};

import axios from 'axios';

import { TPassportData } from '../store/models/passport';

export const cancelSource = axios.CancelToken.source();
const url = `/task/complete`;
const urlPrivacyPolicy = `/complete-privacy-policy`;

const Complete = {
  postComplete: () => axios.post(url),
  postCompletePrivacyPolicy: (body: TPassportData) =>
    axios.post(urlPrivacyPolicy, body, {
      cancelToken: cancelSource.token
    })
};

export default Complete;

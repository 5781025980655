import axios from 'axios';

import type { Dispatch } from '../store';

export interface IFeedbackFields {
  image: string | null;
  dispatch?: Dispatch;
}

const url = `/registration`;
export const cancelSource = axios.CancelToken.source();

const Registration = {
  postRegistration: (body: IFeedbackFields) =>
    axios.post(url, body, {
      onUploadProgress: (progressEvent) =>
        body.dispatch?.imageData.setProgress({ progress: progressEvent.loaded })
    })
};

export default Registration;

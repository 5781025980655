import { createModel } from '@rematch/core';
import { captureException } from '@sentry/react';

import RegistrationService from '../../services/RegistrationService';
import { modalsNames } from '../../utils/constants';
import type { IRootModel } from './index';

export interface IState {
  image: string;
}

const initialState: IState = {
  image: ''
};

export const registrationData = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setRegistrationReducer(state: IState, payload: IState) {
      return { ...state, ...payload };
    }
  },
  effects: (dispatch) => ({
    setRegistration(payload) {
      this.setRegistrationReducer(payload);
    },
    async postRegistration(payload) {
      try {
        dispatch.modalsStatus.setModalStatus([modalsNames.modelLoading, true]);
        await RegistrationService.postRegistration(payload);
        dispatch.modalsStatus.setModalStatus([modalsNames.modelLoading, false]);
      } catch (error) {
        dispatch.modalsStatus.setModalStatus([modalsNames.modelLoading, false]);
        captureException(error);
        throw error;
      }
    }
  })
});

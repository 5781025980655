import { createModel } from '@rematch/core';

import AdressService from '../../services/AdressService';
import { modalsNames } from '../../utils/constants';
import type { IRootModel } from './index';

export interface IState {
  index: string;
  region: string;
  district: string;
  city: string;
  street: string;
  house: string;
  block: string;
  numberFlat: string;
}

const initialState: IState = {
  index: '',
  region: '',
  district: '',
  city: '',
  street: '',
  house: '',
  block: '',
  numberFlat: ''
};

export const adressData = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setAddressReducer(state, payload) {
      return { ...state, ...payload };
    }
  },
  effects: (dispatch) => ({
    setAddress(payload) {
      this.setAddressReducer(payload);
    },
    async postAdress(payload) {
      dispatch.modalsStatus.setModalStatus([modalsNames.emptyLoading, true]);
      await AdressService.postAdress(payload);
      dispatch.modalsStatus.setModalStatus([modalsNames.emptyLoading, false]);
    }
  })
});

import { ReactNode } from 'react';

import styled from 'styled-components';

type TypographyVariants = 'regular' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
type TypographySizes = 'small' | 'regular' | 'big';

interface IProps {
  children: ReactNode;
  size?: TypographySizes;
  variant?: TypographyVariants;
  className?: string;
}

const StyledP = styled.p<{ size: TypographySizes }>`
  font-size: ${({ size }) => {
    if (size === 'small') return '14px';
    if (size === 'big') return '18px';
    return '16px';
  }};
  color: var(--color-grey-raven);
  margin: 0;
`;

const StyledH1 = styled.h1`
  font-size: 64px;
  margin: 0;
  color: var(--color-black);
`;

const StyledH3 = styled.h3`
  margin: 4px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-black);
`;

const StyledH4 = styled.h4`
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  color: var(--color-black);
`;

const StyledH5 = styled.h5`
  margin: 0;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: var(--color-black);
`;

const NewTypography = ({ children, size = 'regular', variant = 'regular', className }: IProps) => {
  if (variant === 'h1') {
    return <StyledH1 className={className}>{children}</StyledH1>;
  }

  if (variant === 'h2') {
    return <h2 className={className}>{children}</h2>;
  }

  if (variant === 'h3') {
    return <StyledH3 className={className}>{children}</StyledH3>;
  }

  if (variant === 'h4') {
    return <StyledH4 className={className}>{children}</StyledH4>;
  }

  if (variant === 'h5') {
    return <StyledH5 className={className}>{children}</StyledH5>;
  }

  return (
    <StyledP className={className} size={size}>
      {children}
    </StyledP>
  );
};

export default NewTypography;

import { createModel } from '@rematch/core';

import { dataType as EData, dataTypeGetParemetre } from '../../utils/constants';
import findGetParameter from '../../utils/findGetParameter';
import type { IRootModel } from './index';

const setInitialState = () => {
  const getParamDataTypeValue = findGetParameter(dataTypeGetParemetre);
  if (Object.values(EData).includes(getParamDataTypeValue as EData)) {
    localStorage.setItem(dataTypeGetParemetre, getParamDataTypeValue);
    return getParamDataTypeValue;
  }
  return localStorage[dataTypeGetParemetre];
};

const initialState: EData = setInitialState() || EData.video;

export const dataType = createModel<IRootModel>()({
  state: initialState
});

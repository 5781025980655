import { IStepsDict } from '../store/models/types';

const camelize = (s: string | undefined) => {
  if (s) {
    return s.replace(/-./g, (x) => x[1].toUpperCase()) as keyof IStepsDict;
  }

  return '' as keyof IStepsDict;
};

export const camelizePathname = (pathname: string) => camelize(pathname.split('/').pop() ?? '');

import { useEffect, useState } from 'react';

import { useAppDispatch } from './redux';

export const useGetConfig = () => {
  const { getConfig } = useAppDispatch().config;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getConfig()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [getConfig]);

  return loading;
};

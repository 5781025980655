import { useEffect, useState } from 'react';

export const useDeviceOrientation = () => {
  const initialOrientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
  const [orientation, setOrientation] = useState(initialOrientation);

  useEffect(() => {
    const mql = window.matchMedia('(orientation: landscape)');

    const handleOrientationChange = (e: { matches: boolean }) => {
      if (e.matches) {
        setOrientation('landscape');
      } else {
        setOrientation('portrait');
      }
    };

    if (mql?.addEventListener) {
      mql.addEventListener('change', handleOrientationChange);
    } else {
      mql.addListener(handleOrientationChange);
    }

    return () => {
      mql.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  return orientation;
};

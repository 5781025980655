import { accessTokenName, refreshTokenName } from './constants';

interface ITocenObj {
  accessToken: string;
  refreshToken: string;
}
const setToken = (tokenObj: ITocenObj) => {
  localStorage.setItem(accessTokenName, `Bearer ${tokenObj.accessToken}`);
  localStorage.setItem(refreshTokenName, tokenObj.refreshToken);
};
const getAccessToken = () => localStorage.getItem(accessTokenName);

const getRefreshToken = () => localStorage.getItem(refreshTokenName);

const clearToken = () => {
  localStorage.removeItem(accessTokenName);
  localStorage.removeItem(refreshTokenName);
};

export { setToken, getAccessToken, getRefreshToken, clearToken };

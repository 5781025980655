import { useLocation, useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import icons from '../../assets/icons';
import { screen } from '../../theme';
import { urls } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/redux';

const StyledBackButton = styled(icons.goBack)`
  display: none;

  @media (max-width: ${screen.xs}) {
    display: block;
    position: absolute;
    cursor: pointer;
    top: 22px;
    left: 34px;
  }

  path {
    fill: var(--color-black);
  }
`;

const BackButton = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setVideoStartPageStatus } = useAppDispatch().videoStartPageStatus;
  const videoStartPageStatus = useAppSelector((state) => state.videoStartPageStatus);
  const { prevPageUrl } = useAppSelector((state) => state.stepper);

  const back = () => {
    if (videoStartPageStatus > 0) {
      setVideoStartPageStatus(0);
    } else {
      navigate(prevPageUrl);
    }
  };

  if (location.pathname !== urls.root && location.pathname !== urls.finish) {
    return <StyledBackButton onClick={back} />;
  }

  return null;
};

export default BackButton;

const findGetParameter = (parameterName: string): string => {
  let result = '';
  let tmp = [];
  window.location.search
    .substr(1)
    .split('&')
    .forEach((item: string) => {
      tmp = item.split('=');
      if (tmp[0] === parameterName) {
        result = decodeURIComponent(tmp[1]);
      }
    });
  return result;
};

export default findGetParameter;

import { Typography } from '@mui/material';
import styled from 'styled-components';

const StyledTypography = styled(Typography)<{ color: string }>`
  color: ${({ color }) => color || 'var(--color-text-primary)'};
`;

const TopTypographyX = ({ children, Pcolor, className, ...props }: any) => (
  <StyledTypography color={Pcolor} className={className} {...props}>
    {children}
  </StyledTypography>
);

export default TopTypographyX;

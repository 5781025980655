import { AxiosError } from 'axios';

import { APIRequestURLs, modalsNames } from './constants';

const errors: Record<string, string> = {
  'BioPlatform error': modalsNames.platformConnectionError,
  'Face not found': modalsNames.passportNoPhotoError,
  'Incorrect passport format': modalsNames.photoFormatError,
  'Invalid format image': modalsNames.platformBeforeUploadError,
  'Passport check error': modalsNames.passportNoValidError,
  'Passport and selfie match error': modalsNames.photoNotMatchError,
  'Passport and selfie do not match': modalsNames.photoNotMatchError,
  'Unexpected error': modalsNames.platformUnexpectedError,
  default: modalsNames.passportVideoModalError
};

export const postRecogniseErrorHandler = (error: AxiosError, dispatch: any) => {
  if (
    error.response &&
    error.response.status === 400 &&
    error.config.url !== APIRequestURLs.refresh
  ) {
    const errorMessage = error.response.data.error;
    let modalName = errors.default;
    Object.entries(errors).forEach(([key, value]) => {
      if (errorMessage.includes(key)) {
        modalName = value;
      }
    });
    dispatch.modalsStatus.setModalStatus([modalName, true]);
  } else {
    dispatch.modalsStatus.setModalStatus([modalsNames.requestTimeoutFailedError, true]);
  }
};

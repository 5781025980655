/**
 * Конфиг с настройками Axios обращения к API сервера
 */
import { captureException } from '@sentry/react';
import axios from 'axios';

import * as localStorageService from '../../utils/localStorage';

/**
 * Base url для проксирования запросов на сервер
 */
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.post['Content-Type'] = 'application/json';
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = localStorageService.getAccessToken();
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 403 &&
      // eslint-disable-next-line no-underscore-dangle
      !originalRequest._retry &&
      originalRequest.url !== '/oauth/refresh'
    ) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      return (
        axios
          .post('/oauth/refresh', {
            refresh_token: localStorageService.getRefreshToken()
          })
          // eslint-disable-next-line consistent-return
          .then((res) => {
            if (res.status === 200) {
              // 1) put token to LocalStorage
              localStorageService.setToken({
                accessToken: res.data.access_token,
                refreshToken: res.data.refresh_token
              });

              // 2) Change Authorization header
              axios.defaults.headers.common.Authorization = localStorageService.getAccessToken();

              // 3) return originalRequest object with Axios.
              return axios(originalRequest);
            }
          })
      );
    }
    captureException(error);
    // return Error object with Promise
    return Promise.reject(error);
  }
);

interface IProps {
  className?: string;
  right?: boolean;
  left?: boolean;
}

const MTSEggBorder = ({ className, right = false, left = false }: IProps) => (
  <svg
    className={className}
    height="60%"
    width="70%"
    y="5%"
    viewBox="0 0 392 524"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M45.6584 396.25C18.3681 340.734 2 274.045 2 211.71C2 167.6 10.196 130.801 24.6584 100.984"
        stroke={left ? 'var(--color-dark-apple)' : 'var(--color-white)'}
        strokeWidth={left ? 10 : 6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M367.49 101.29C381.86 131.053 390 167.751 390 211.71C390 273.803 373.759 340.217 346.658 395.603"
        stroke={right ? 'var(--color-dark-apple)' : 'var(--color-white)'}
        strokeWidth={right ? 10 : 6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default MTSEggBorder;

import { createModel } from '@rematch/core';

import type { IRootModel } from './index';

export interface IState {
  count: number;
  videoDevices: string[];
}

const initialState: IState = {
  count: -1,
  videoDevices: []
};

export const camerasCount = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setCamerasCountReducer(state: IState, payload) {
      return payload;
    }
  },
  effects: {
    setCamerasCount() {
      navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
        let count = 0;
        const videoDevices: string[] = [];
        mediaDevices.forEach((mediaDevice) => {
          if (mediaDevice.kind === 'videoinput') {
            count += 1;
            videoDevices.push(mediaDevice.deviceId);
          }
        });
        this.setCamerasCountReducer({ count, videoDevices });
      });
    }
  }
});

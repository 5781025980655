import { StepsProvider } from '../../routes/RootPage/StepsProvider';
import { useGetConfig } from '../../utils/hooks/useGetConfig';
import CircularProgress from '../CircularProgress';
import StyledLoaderContainer from '../CircularProgress/Container';

const ConfigLoader = () => {
  const loading = useGetConfig();

  if (loading) {
    return (
      <StyledLoaderContainer>
        <CircularProgress />
      </StyledLoaderContainer>
    );
  }

  return <StepsProvider />;
};

export default ConfigLoader;

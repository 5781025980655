import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { captureException } from '@sentry/react';

import icons from '../../assets/icons';
import ModalDialog from '../ModalDialog';

const ErrorModal = () => {
  const error = useRouteError();

  useEffect(() => {
    captureException(error);
  }, [error]);

  const reload = () => {
    window.location.href = '/';
  };

  return (
    <ModalDialog
      icon={<icons.failed />}
      textHeader="Ошибка"
      open
      textContent="Что-то пошло не так, повторите попытку"
      submitButtonText="Обновить"
      onSubmit={reload}
    />
  );
};

export default ErrorModal;

import { Breakpoints, Screen } from './types';

export const screen: Screen = {
  xs: '600px',
  sm: '720px',
  md: '1200px',
  lg: '1920px',
  xl: '2500px',
  xxl: '3500px'
};

export const breakpoints: Breakpoints = {
  down: {
    xs: `(max-width: ${screen.xs})`,
    sm: `(max-width: ${screen.sm})`,
    md: `(max-width: ${screen.md})`,
    lg: `(max-width: ${screen.lg})`,
    xl: `(max-width: ${screen.xl})`,
    xxl: `(max-width: ${screen.xxl})`
  },
  up: {
    xs: `(min-width: ${screen.xs})`,
    sm: `(min-width: ${screen.sm})`,
    md: `(min-width: ${screen.md})`,
    lg: `(min-width: ${screen.lg})`,
    xl: `(min-width: ${screen.xl})`,
    xxl: `(min-width: ${screen.xxl})`
  }
};

import axios from 'axios';

export interface IFeedbackFields {
  image: string | null;
  // eslint-disable-next-line
  dispatch?: any;
  // eslint-disable-next-line camelcase
  selfie_source: 'from_camera' | 'from_disk';
}

const url = `/selfie`;
export const cancelSource = axios.CancelToken.source();

const Photo = {
  postSelfie: (body: IFeedbackFields) => {
    if (body.selfie_source === 'from_disk') {
      return axios.post(url, body, {
        onUploadProgress: (progressEvent) =>
          body.dispatch.imageData.setProgress({ progress: progressEvent.loaded })
      });
    }
    return axios.post(url, body);
  }
};

export default Photo;

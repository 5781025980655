import { createModel } from '@rematch/core';
import { captureException } from '@sentry/react';
import { AxiosError } from 'axios';
import { parse } from 'date-fns';

import PhotoServices from '../../services/PhotoService';
import RegistrationService from '../../services/RegistrationService';
import SelfieService from '../../services/SelfieService';
import { modalsNames } from '../../utils/constants';
import { postRecogniseErrorHandler } from '../../utils/postRecogniseErrorHandler';
import { postSelfieErrorHandler } from '../../utils/postSelfieErrorHandler';
import type { IRootModel } from './index';

export interface IImageState {
  image: string;
  imageServer: string;
  imageSelfie: string;
  progress: number;
  isLoaded: boolean;
}

const initialState: IImageState = {
  image: '',
  imageServer: '',
  imageSelfie: '',
  progress: 0,
  isLoaded: false
};

export const imageData = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setPhoto(state: IImageState, payload: IImageState) {
      return { ...state, image: payload.image };
    },
    setServerPhoto(state: IImageState, payload: IImageState) {
      return { ...state, imageServer: payload.imageServer };
    },
    setSelfiePhoto(state: IImageState, payload: IImageState) {
      return { ...state, imageSelfie: payload.imageSelfie };
    },
    setProgress(state: IImageState, payload) {
      return { ...state, progress: payload.progress };
    },
    setIsLoaded(state: IImageState, payload) {
      return { ...state, isLoaded: payload.isLoaded };
    }
  },
  effects: (dispatch) => ({
    async postPhoto(payload) {
      dispatch.postPhotoStatus.setPostPhotoStatus(true);
      const savedImage = payload.image;
      try {
        let { data } = await PhotoServices.postPhoto({ ...payload, dispatch });
        dispatch.postPhotoStatus.setPostPhotoStatus(false);
        data = data.result;
        if (Object.keys(data.passportData).length !== 0) {
          this.setServerPhoto({ imageServer: data.image });
          this.setPhoto({ image: savedImage });
          dispatch.verificate.setVerification(data.verification);
          data.verification.result = data.verification.result === 'true';
          data.verification.status = data.verification.status === 'true';
          if (!data.verification.result && data.verification.status) {
            dispatch.modalsStatus.setModalStatus([modalsNames.verificationModalError, true]);
          } else {
            dispatch.verificate.setVerification(data.verification);
            dispatch.passportStatus.setPassportStatus(true);
            const recognitionAccuracyResult = Object.values({
              number: data.passportData.number,
              name: data.passportData.name,
              surname: data.passportData.surname,
              birthPlace: data.passportData.birthPlace,
              series: data.passportData.series,
              gender: data.passportData.gender,
              birthDate: data.passportData.birthDate,
              departmentCode: data.passportData.departmentCode,
              deliveryPlace: data.passportData.deliveryPlace,
              deliveryDate: data.passportData.deliveryDate
            }).some((item) => item === null);
            dispatch.recognitionAccuracy.setRecognitionAccuracy(!recognitionAccuracyResult);
            dispatch.passportData.setPassport({
              ...data.passportData,
              birthDate: parse(data.passportData.birthDate, 'dd.MM.yyyy', new Date()),
              deliveryDate: parse(data.passportData.deliveryDate, 'dd.MM.yyyy', new Date())
            });
          }
          this.setIsLoaded({ isLoaded: true });
        } else {
          dispatch.modalsStatus.setModalStatus([modalsNames.passportVideoModalError, true]);
          dispatch.postPhotoStatus.setPostPhotoStatus(false);
          captureException(`Error: no passport data`);
        }
      } catch (error) {
        postRecogniseErrorHandler(error as AxiosError, dispatch);
        dispatch.postPhotoStatus.setPostPhotoStatus(false);
        captureException(error);
        throw error;
      }
    },

    async postSelfie(payload) {
      try {
        const imageSelfie = payload.image;
        await SelfieService.postSelfie({ ...payload, dispatch });
        this.setIsLoaded({ isLoaded: true });
        this.setSelfiePhoto({ imageSelfie });
      } catch (error) {
        postSelfieErrorHandler(error as AxiosError, dispatch);
        captureException(error);
        throw error;
      }
    },

    async postLunaSelfie(payload) {
      try {
        const selfieImage = payload.image;
        const response = await SelfieService.postSelfie({ ...payload, dispatch });
        if (response.data.result) {
          this.setIsLoaded({ isLoaded: true });
          this.setSelfiePhoto({ imageSelfie: selfieImage });
        } else {
          dispatch.modalsStatus.setModalStatus([modalsNames.selfieVideoModalError, true]);
        }
      } catch (error) {
        postSelfieErrorHandler(error as AxiosError, dispatch);
        captureException(error);
        throw error;
      }
    },

    async postRegistration(payload, { config, stepper }) {
      try {
        dispatch.modalsStatus.setModalStatus([modalsNames.modelLoading, true]);
        await RegistrationService.postRegistration({ ...payload, dispatch });
        this.setIsLoaded({ isLoaded: true });
        dispatch.modalsStatus.setModalStatus([modalsNames.modelLoading, false]);
        if (stepper.activeStep === config.steps.count) {
          dispatch.complete.postComplete();
        }
      } catch (error) {
        dispatch.modalsStatus.setModalStatus([modalsNames.modelLoading, false]);
        captureException(error);
        throw error;
      }
    },

    async postSavePhoto(payload) {
      try {
        await PhotoServices.postSavePhoto(payload);
      } catch (error) {
        dispatch.modalsStatus.setModalStatus([modalsNames.modelLoading, false]);
        captureException(error);
        throw error;
      }
    }
  })
});

import { Provider } from 'react-redux';

import { CssBaseline, GlobalStyles } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from "@sentry/react";

import ConfigLoader from './components/ConfigLoader';
import NetworkError from './components/NetworkError';
import { store } from './store';
import { useCustomTheme } from './utils/hooks/useCustomTheme';

const App = () => {
  useCustomTheme();

  return (
    <Sentry.ErrorBoundary>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <GlobalStyles styles={{ body: { fontFamily: 'MTSCompact' } }} />
          <NetworkError />
          <ConfigLoader />
        </LocalizationProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

export default Sentry.withProfiler(App);

import { rgbToHex } from '@mui/material';

const getValidColor = (color: string): string => {
  const hexRegex = /^#([a-f0-9]{6}|[a-f0-9]{3})$/;
  const rgbRegex = /^rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)$/;

  if (hexRegex.test(color)) {
    return color;
  }
  if (rgbRegex.test(color)) {
    return rgbToHex(color);
  }
  return '';
};

const findColorParameter = (parameterName: string, defaultColor: string): string => {
  const urlParams = new URLSearchParams(window.location.href);
  if (!urlParams.has(parameterName)) {
    return '';
  }
  const color = urlParams.get(parameterName) as string;
  if (color === '') {
    return defaultColor;
  }
  const validColor = getValidColor(color.toLowerCase());
  if (validColor) {
    return validColor;
  }
  return defaultColor;
};
export default findColorParameter;

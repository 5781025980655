import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

import * as Sentry from "@sentry/react";

import App from './App';
import * as serviceWorker from './serviceWorker';

import './config/axios/axiosConfig';
import './index.css';

const SENTRY_DSN = 'https://cb8f3a611f574fcfa43fc4175536212a@sentry.obs.mts.ru/415';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    })
  ],
  tracesSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

serviceWorker.unregister();

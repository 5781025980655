export interface AnalyticsState {
  userId: string | null;
  userAuth: string | null;
  abonent: number | null;
  numberType: string | null;
  accountType: string | null;
  touchPoint: string | null;
  currentTariff: string | null;
  buttonLocation: string | null;
  event: string | null;
  eventCategory: 'biometriya' | null;
}

export interface Event extends AnalyticsState {
  eventAction: string | null;
  eventLabel: string | null;
  screenName: string | null;
  actionGroup: string | null;
  eventContext: string | null;
}

export const showStartScreen = (data: AnalyticsState, page: string | null): Event => ({
  ...data,
  event: 'scrn',
  eventAction: '',
  eventLabel: '',
  screenName: `/account/personal/biometry${page}`,
  actionGroup: 'non_interactions',
  eventContext: null
});

export const clickStartButton = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'nachat',
  screenName: '/account/personal/biometry/add',
  actionGroup: 'interactions',
  eventContext: null
});

export const clickStartScanButton = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'nachat_skanirovanie',
  screenName: '/account/personal/biometry/add/photo-face',
  actionGroup: 'interactions',
  eventContext: null
});

export const noCameraAlert = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'element_show',
  eventLabel: 'na_ustroistve_net_kamery',
  screenName: '/account/personal/biometry/add/photo-face',
  actionGroup: 'non_interactions',
  eventContext: null
});

export const noAccessCameraAlert = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'element_show',
  eventLabel: 'net_dostupa_k_kamere',
  screenName: '/account/personal/biometry/add/photo-face',
  actionGroup: 'non_interactions',
  eventContext: 'net_dostupa_k_kamere'
});

export const reloadAccessCamera = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'obnovit',
  screenName: '/account/personal/biometry/add/photo-face',
  actionGroup: 'interactions',
  eventContext: 'net_dostupa_k_kamere'
});

export const clickMakePhoto = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'sdelat_foto',
  screenName: '/account/personal/biometry/add/photo-face',
  actionGroup: 'interactions',
  eventContext: null
});

export const closeAfterCantRecognizeFace = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'zakryt',
  screenName: '/account/personal/biometry/add/photo-face',
  actionGroup: 'interactions',
  eventContext: 'ne_udalos_raspoznat_lico'
});

export const reloadAfterCantRecognizeFace = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'povtorit',
  screenName: '/account/personal/biometry/add/photo-face',
  actionGroup: 'interactions',
  eventContext: 'ne_udalos_raspoznat_lico'
});

export const recognizePhotoSuccess = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'confirmed',
  eventLabel: 'raspoznavanie_foto_lica',
  screenName: '/account/personal/biometry/add/photo-face',
  actionGroup: 'conversions',
  eventContext: null
});

// TODO: добавить, когда появится иконка QR кода
export const qrCodeClick = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'element_click',
  eventLabel: 'qr-kod',
  screenName: '/account/personal/biometry/add/photo-passport',
  actionGroup: 'interactions',
  eventContext: null
});

export const showRecognizePassportBlock = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'element_show',
  eventLabel: 'blok_raspoznavaniya_pasporta',
  screenName: '/account/personal/biometry/add/photo-passport',
  actionGroup: 'non_interactions',
  eventContext: null
});

export const showErrorCantRecognizePassport = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'element_show',
  eventLabel: 'oshibka_raspoznavaniya_pasporta',
  screenName: '/account/personal/biometry/add/photo-passport',
  actionGroup: 'non_interactions',
  eventContext: 'oshibka_raspoznavaniya_pasporta'
});

export const closeAfterCantRecognizePassport = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'zakryt',
  screenName: '/account/personal/biometry/add/photo-passport',
  actionGroup: 'interactions',
  eventContext: 'oshibka_raspoznavaniya_pasporta'
});

export const reloadAfterCantRecognizePassport = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'povtorit',
  screenName: '/account/personal/biometry/add/photo-passport',
  actionGroup: 'interactions',
  eventContext: 'oshibka_raspoznavaniya_pasporta'
});

export const showErrorCompareSelfieAndPassport = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'element_show',
  eventLabel: 'selfi_i_foto_v_pasporte_ne_sovpali',
  screenName: '/account/personal/biometry/add/photo-passport',
  actionGroup: 'non_interactions',
  eventContext: 'selfi_i_foto_v_pasporte_ne_sovpali'
});

export const closeAfterCompareSelfieAndPassport = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'zakryt',
  screenName: '/account/personal/biometry/add/photo-passport',
  actionGroup: 'interactions',
  eventContext: 'selfi_i_foto_v_pasporte_ne_sovpali'
});

export const reloadAfterCompareSelfieAndPassport = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'povtorit',
  screenName: '/account/personal/biometry/add/photo-passport',
  actionGroup: 'interactions',
  eventContext: 'selfi_i_foto_v_pasporte_ne_sovpali'
});

export const passportScanSuccess = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'confirmed',
  eventLabel: 'dannye_pasporta_uspeshno_raspoznany',
  screenName: '/account/personal/biometry/add/photo-passport',
  actionGroup: 'conversions',
  eventContext: 'dannye_pasporta_uspeshno_raspoznany'
});

export const clickSubmitPassportScanSuccess = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'podtverdit',
  screenName: '/account/personal/biometry/add/photo-passport',
  actionGroup: 'interactions',
  eventContext: 'dannye_pasporta_uspeshno_raspoznany'
});

export const clickRepeatPassportScanSuccess = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'povtorit_skanirovanie',
  screenName: '/account/personal/biometry/add/photo-passport',
  actionGroup: 'interactions',
  eventContext: 'dannye_pasporta_uspeshno_raspoznany'
});

export const showMessageDataAdded = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'confirmed',
  eventLabel: 'vashi_dannye_dobavleny',
  screenName: '/account/personal/biometry/add/polzovatelskoe_soglashenie',
  actionGroup: 'conversions',
  eventContext: 'dannye_dobavleny'
});

export const showMessageBioProfileCreated = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'confirmed',
  eventLabel: 'bioprofil_uspeshno_sozdan',
  screenName: '/account/personal/biometry/add/polzovatelskoe_soglashenie',
  actionGroup: 'conversions',
  eventContext: 'bioprofil_sozdan'
});

export const clickGoToAgreement = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'pereiti_k_soglasiu',
  screenName: '/account/personal/biometry/add/polzovatelskoe_soglashenie',
  actionGroup: 'interactions',
  eventContext: 'dannye_dobavleny'
});

export const clickAcceptAgreement = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'checkbox_switch',
  eventLabel: 'prinimau_usloviya_polzovatelskogo_soglasheniya',
  screenName: '/account/personal/biometry/add/polzovatelskoe_soglashenie',
  actionGroup: 'interactions',
  eventContext: null
});

export const clickAcceptButtonAgreement = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'soglasen',
  screenName: '/account/personal/biometry/add/polzovatelskoe_soglashenie',
  actionGroup: 'interactions',
  eventContext: null
});

export const clickOkButtonAgreement = (data: AnalyticsState): Event => ({
  ...data,
  eventAction: 'button_click',
  eventLabel: 'horosho',
  screenName: '/account/personal/biometry/add/polzovatelskoe_soglashenie',
  actionGroup: 'bioprofil_sozdan',
  eventContext: null
});

declare global {
  interface Window {
    dataLayer: string[];
  }
}

export const sendAnalytics = (data: Event) => {
  if (window.dataLayer) {
    window.dataLayer.push(data as any);
  }
};

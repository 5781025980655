import { IStepsDict } from '../store/models/types';

const getStepsUrl = (steps: Partial<IStepsDict>) => {
  const stepsUrl = Object.entries(steps).reduce((acc: { [key: number]: string }, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {}) as { [key: number]: keyof IStepsDict };

  return stepsUrl;
};

export const getStepsDict = (dict: {
  [key: string]: boolean;
}): { [key: string]: Partial<IStepsDict> } => {
  let stepsCount = 0;

  const stepsDict = {
    root: 0,
    ...(dict.identity && {
      selfieVideo: (stepsCount += 1),
      selfieStart: stepsCount
    }),
    ...(dict.recognize_pass && {
      passportVideo: (stepsCount += 1),
      passportStart: stepsCount
    }),
    ...(dict.recognize_pass &&
      !dict.without_passport_page && {
        passportForm: (stepsCount += 1)
      }),
    ...(dict.registration && {
      registrationVideo: (stepsCount += 1),
      registrationStart: stepsCount
    }),
    ...(dict.address && {
      address: (stepsCount += 1)
    }),
    ...(dict.privacy_policy && {
      agreement: (stepsCount += 1)
    }),
    finish: (stepsCount += 1)
  };

  return {
    dict: stepsDict,
    url: getStepsUrl(stepsDict)
  };
};
